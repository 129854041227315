/**
 * Página Admin Punto Venta:
 * 
 * acá se Adminsitran los pedidos del punto de venta
 * 
 * @module AdminPuntoVentajsx
 */


import { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ModalDialog from 'react-bootstrap/ModalDialog';
import parse from 'html-react-parser';

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const PuntoVenta = () => {


  /**
* @constant
* @name pedidosAdmin
* @type {Array}
* 
* */

  const [pedidosAdmin, setPedidosAdmin] = useState([]);

  /**
* @constant
* @name foto
* @type {string}
* 
* */

  const [foto, setFoto] = useState('');


  /**
* @constant
* @name showFoto
* @type {boolean}
* 
* */
  const [showFoto, setShowFoto] = useState(false);

  /**
* @constant
* @name currentPage
* @type {number}
* 
* */
  const [currentPage, setCurrentPage] = useState(1);

  /**
* @constant
* @name estado
* @type {number}
* 
* */
  const [estado, setEstado] = useState(1);

  /**
* @constant
* @name totalPaginas
* @type {string}
* 
* */
  const [totalPaginas, setTotalPaginas] = useState('');

  /**
* @constant
* @name show
* @type {boolean}
* 
* */
  const [show, setShow] = useState(false);

  /**
* @constant
* @name modal_body
* @type {string}
* 
* */
  const [modal_body, setModalBody] = useState('');

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");
  const [mesFiltro, setMesFiltro] = useState(parseInt(new Date().getMonth()) + 1);
  const [anioFiltro, setAnioFiltro] = useState(new Date().getFullYear());

  /**
* @constant
* @name choferes
* @type {Array}
* 
* */
  const [choferes, setChoferes] = useState([]);
  let mesesLlenar = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  let aniosLlenar = [];

  const handleClose = () => {
    setModalBody('');
    setShow(false);
  }
  const handleShowFoto = () => setShowFoto(true);

  const cargaFoto = (foto) => {
    setFoto(foto);
    handleShowFoto();
  }

  const handleCloseFoto = () => {
    setShowFoto(false);
  }

  const handleShow = () => setShow(true);

  const accessToekn = sessionStorage.getItem("access");

  const handleAnioChange = (e) => {
    setAnioFiltro(e.target.value);
  };

  const handleMesChange = (e) => {
    setMesFiltro(parseInt(e.target.value) + 1);
  };

  const llenarAnio = () => {
    const year = new Date().getFullYear();
    let retornar = "";
    for (let i = year; i >= 1900; i--) {
      aniosLlenar.push(i);
    }
  };

  const filtrar = (e) => {
    obtenerPedidosAdmin();
  };


  /**
* @function
* Petición HTTP que obtiene los choferes listados desde la pagina 1 y con un maximo de registros de 10.0000
* @returns {Array}
*/

  const obtenerChoferes = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/listar_choferes?page=1&size=10000`);
      setChoferes(response.data.results);
    }
    catch (error) {
      console.log(error);
    }
  };


  /**
* @function
* Metodo crear una fecha en base al Objeto Date de Js
* @returns {string}
*/

  const getFecha = (fecha) => {

    let fecha_compra = new Date(fecha);
    let dia = fecha_compra.getDate();
    let mes = fecha_compra.getMonth() + 1;
    let anio = fecha_compra.getFullYear();

    let hour = fecha_compra.getHours();
    let minutes = fecha_compra.getMinutes();
    let seg = fecha_compra.getSeconds();


    dia = dia <= 9 ? '0' + dia : dia;
    mes = mes <= 9 ? '0' + mes : mes;
    minutes = minutes <= 9 ? '0' + minutes : minutes;
    seg = seg <= 9 ? '0' + seg : seg;

    return `${dia}-${mes}-${anio} ${hour}:${minutes}:${seg}`;

  }


  /**
* @function
* Metodo para mostrar el detalle de la compra en un modal
* @param {json} detalle_compra Objeto que contiene el detalle de la compra
* @returns {string}
*/
  const mostrarDetalleCompra = (detalle_compra) => {

    let detalles = detalle_compra;
    let dc = '';

    detalles.map((index) => {

      let row = '';

      row = `
    
        <div className="row justify-content-center text-center">
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                <span>${index.sku}</span>
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                <img loading="lazy" width="100px" height="auto" src="${process.env.REACT_APP_FRONT_URL + index.foto_1}" aria-label="${index.producto_nombre}">
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                ${index.producto_nombre}
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                ${index.cantidad}
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                $${Intl.NumberFormat('es-CL').format(index.precio_unitario * index.cantidad)}
            </div>

            <div className="col-10 text-center">
              <hr>
            </div>

        </div>
      
        `;

      dc += row;

    })

    setModalBody(dc);

    handleShow();

  };



  /**
* @function
* Petición HTTP que obtiene los pedidos filtrados por mes , año y estado
* @param {number} currentPage Pagina actual
* @param {string} anioFiltro Año para filtrar los pedidos
* @param {string} mesFiltro Mes para filtror los pedidos
* @param {number} estado  estado de los pedidos a filtrar
* @returns {Array}
*/

  const obtenerPedidosAdmin = async () => {
    try {

      const response = await axios.get(
        process.env.REACT_APP_BACK_URL + `/api/admin/listar_pdv?page=${currentPage}&size=10&estado=${estado}&anio_filtro=${anioFiltro}&mes_filtro=${mesFiltro}`, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        }
      }
      );

      setPedidosAdmin(response.data.results);
      setTotalPaginas(response.data.pages);

    } catch (error) {
      console.error(error);
    }
  };

  /**
* @function
* Petición HTTP que obtiene los pedidos filtrados por mes , año y estado
* @param {number} currentPage Pagina actual
* @param {string} anioFiltro Año para filtrar los pedidos
* @param {string} mesFiltro Mes para filtror los pedidos
* @param {number} estado  estado de los pedidos a filtrar
* @returns {Array}
*/


  useEffect(() => {
    const obtenerPedidosAdmin = async () => {
      try {

        const response = await axios.get(
          process.env.REACT_APP_BACK_URL + `/api/admin/listar_pdv?page=${currentPage}&size=10&estado=${estado}&anio_filtro=${anioFiltro}&mes_filtro=${mesFiltro}`, {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          }
        }
        );

        setPedidosAdmin(response.data.results);
        setTotalPaginas(response.data.pages);

      } catch (error) {
        console.error(error);
      }
    };

    obtenerPedidosAdmin();
    obtenerChoferes();

  }, [currentPage, estado]);



  /**
* @function
* Petición HTTP que cambia el estado de la transferencia
* @param {number} estado Estado por el cual se cambiara
* @param {string} id_pedido Id del pedido actual
* @returns {Array}
*/

  const cambiarEstado = (e) => {

    let id_pedido = e.target.getAttribute('data-set-id');
    let estado = e.target.value;

    e.preventDefault();
    setMensaje("Cambiando estado-alert");
    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/cambiar_estado_comprapuntoventa?transferencia=${estado}&compra=${id_pedido}`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {
      setMensaje("Estado cambiado-verde");
      obtenerPedidosAdmin();

    }).catch(error => {
      console.log(error);
      setMensaje("Error al cambiar el estado-rojo");
    })

  };


  /**
* @function
* Petición HTTP que cambia el estado del despacho de una compra
* @param {string} despacho Estado de despacho
* @param {string} id_pedido Id del pedido
* @returns {Array}
*/
  const cambiarDespacho = (e) => {
    let id_pedido = e.target.getAttribute('data-set-id');
    let despacho = e.target.value;
    setMensaje("Cambiando despacho-alert");
    e.preventDefault();

    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/cambiar_estado_comprapuntoventa?despacho=${despacho}&compra=${id_pedido}`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {
      setMensaje("Despacho cambiado-verde");
      obtenerPedidosAdmin();

    }).catch(error => {
      setMensaje("Error al cambiar el despacho-rojo");
    })
  }

  /**
* @function
* Petición HTTP que asigna un chofer al pedido seleccionado
* @param {number} id_compra Id del pedido
* @param {string} chofer Id del chofer para asignar
* @returns {Array}
*/
  const asignarChofer = async (e) => {
    let id_compra = e.target.getAttribute('data-set-id');
    setMensaje("Asignando chofer-alert");
    const response = await axios.get(
      process.env.REACT_APP_BACK_URL + `/api/admin/asignar_chofer_pedido_pdv?compra=${id_compra}&chofer=${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {

      obtenerPedidosAdmin();
      setMensaje("Chofer asignado-verde");

    }).catch(error => {
      setMensaje("Error al asignar el chofer-rojo");
    })
  };

  return (
    <div>
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />

      <Modal show={showFoto} onHide={handleCloseFoto} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className=" container" >

            <div className="row justify-content-center text-center col-12 mb-4">
              <div className="col-12 text-center align-self-center">
                {foto != "" ? (
                  <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + foto} alt="" id="fotoMostrar" className="img-fluid" />
                ) : (
                  <div><strong>N/A</strong></div>
                )}
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center mb-5">

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>SKU</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Foto</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Nombre</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Cantidad</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Sub total</strong>
              </div>
            </div>
          </div>
          <div className=" container" >
            {modal_body != '' ? (
              parse(modal_body)
            ) : (

              <div className="row my-5">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                  <i className="fa-solid fa-circle-info"></i>&nbsp;No hay detalle del pedido
                </div>
              </div>

            )}
          </div>

        </Modal.Body>
      </Modal>

      <div className="regular">
        <h1>Dreamtec - <span>Administrador de punto de venta</span></h1>

        <div className="shadow">

          <div className="row">

            <div className="col-12 text-center">

              <h2>Listado de ventas</h2>

            </div>
            <div className="col-12 mt-4">

              <div className="row d-flex justify-content-center mb-4 mt-1">
                <div className="col-lg-2 col-md-4 col-12 text-center mt-1">
                  <select id='anioSelect' onChange={handleAnioChange} className="form-select rounded-pill">
                    {llenarAnio()}
                    {
                      aniosLlenar.map((anio) => {
                        return <option value={anio}>{anio}</option>
                      })
                    }
                  </select>
                </div>

                <div className="col-lg-2 col-md-4 col-12 text-center mt-1 mb-4">
                  <select id="mesSelect" onChange={handleMesChange} className="form-select rounded-pill">
                    {
                      mesesLlenar.map((mesesLlenar, index) => {
                        if ((index + 1) == mesFiltro) {
                          return <option value={index} selected>{mesesLlenar}</option>
                        }
                        else {
                          return <option value={index}>{mesesLlenar}</option>
                        }
                      })}
                  </select>
                </div>

                <div className="col-lg-2 col-md-4 col-12 mt-1">
                  <button className="agregar border-0 btnCelesteDreamtec text-white " onClick={filtrar} aria-label="filtrar">Filtrar</button>
                </div>
              </div>

              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">

                  <button aria-label="Ver pedidos aprobados"
                    className="agregar"
                    style={{
                      backgroundColor:
                        estado == 2 ? "#0DCAF0" : "",
                      color: estado === 2 ? 'white' : "",
                    }}
                    onClick={() => { setEstado(2) }}
                  >
                    <i className="fa-sharp fa-solid fa-circle-check"></i> &nbsp; Pedidos Pagados
                  </button>

                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">

                  <button aria-label="Ver pedidos pendientes"
                    className="agregar"
                    style={{
                      backgroundColor:
                        estado == 1 ? "#0DCAF0" : "",
                      color: estado === 1 ? 'white' : "",
                    }}
                    onClick={() => { setEstado(1) }}
                  >
                    <i className="fa-solid fa-hourglass-end"></i> &nbsp; Pedidos Con Pago Pendiente
                  </button>

                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">

                  <button aria-label="Ver pedidos abandonados"
                    className="agregar"
                    style={{
                      backgroundColor:
                        estado == 3 ? "#0DCAF0" : "",
                      color: estado === 3 ? 'white' : "",
                    }}
                    onClick={() => { setEstado(3) }}
                  >
                    <i className="fa-solid fa-circle-stop"></i> &nbsp; Pedidos Abandonados y Anulados
                  </button>

                </div>
              </div>

              <div className="row">

                {estado == 1 || estado == 3 ? (

                  <div className="col-12">
                    <div className="wrapper1">
                      <div className="wrapper2">

                        {pedidosAdmin.length > 0 ? (

                          <table className="table border">
                            <thead className="p-4">
                              <tr>
                                <th className="col"><div style={{ width: "100px" }} className="id">ID</div></th>
                                <th className="col"><div style={{ width: "200px" }} className="id">Detalle Compra</div></th>
                                <th className="col"><div style={{ width: "200px" }} className="id">F. Compra</div></th>
                                <th className="col"><div style={{ width: "150px" }} className="id">Total</div></th>
                                <th className="col"><div style={{ width: "150px" }} className="id">Datos de Cliente</div></th>
                                <th className="col"><div style={{ width: "150px" }} className="id">Datos de Contacto</div></th>
                                {estado == 1 ?
                                  <>
                                    <th className="col"><div style={{ width: "150px" }} className="id">Tipo Pago</div></th>
                                    <th className="col"><div style={{ width: "100px" }} className="id">Documento</div></th>
                                  </>
                                  : null
                                }
                                {estado == 1 ?
                                  <th className="col"><div style={{ width: "200px" }} className="id">Acciones</div></th>
                                  :
                                  <th className="col"><div style={{ width: "200px" }} className="id">Estado</div></th>
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {pedidosAdmin.map((pedido) => (

                                <tr key={pedido.compra_pdv_id}>
                                  <td ><div style={{ width: "100px" }} className="nameNumber text-center">{pedido.compra_pdv_id}</div></td>
                                  <td ><div style={{ width: "200px" }} className="nameNumber text-center align-self-center">
                                    <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="btnGreen" style={{
                                      backgroundColor: "#0d6efd"
                                    }}
                                      onClick={() => { mostrarDetalleCompra(pedido.detalle_compra) }}><i className="fa-solid fa-eye"></i>
                                    </button>
                                  </div></td>
                                  <td ><div style={{ width: "200px" }} className="nameNumber text-center">{getFecha(pedido.fecha_compra)}</div></td>
                                  <td ><div style={{ width: "150px" }} className="nameNumber text-center">{"$" + Intl.NumberFormat('es-CL').format(pedido.total)}</div></td>
                                  <td >
                                    <div style={{ width: "150px" }} className="nameNumber text-center">
                                      {pedido.first_name + " " + pedido.last_name}
                                      <br />
                                      {pedido.rut}
                                    </div>
                                  </td>
                                  <td >
                                    <div style={{ width: "150px" }} className="nameNumber text-center">
                                      {pedido.email}
                                      <br />
                                      {pedido.telefono}
                                    </div>
                                  </td>

                                  {estado == 1 ?
                                    <>
                                      <td ><div style={{ width: "150px" }} className="nameNumber text-center">

                                        {pedido.tipo_pago == 1 ? <div><i className="fa-solid fa-building-columns"></i> <i className="fa-solid fa-money-bill-transfer text-success"></i> <i className="fa-solid fa-building-columns"></i> <br></br> Transferencia</div> : null}

                                        {pedido.tipo_pago == 2 ? <div><i className="fa-solid fa-handshake" style={{ color: "#0450FF" }}></i> <br></br> Mercado Pago</div> : null}

                                        {pedido.tipo_pago == 3 ? <div><i className="fa-solid fa-money-bill"></i> <br></br> Efectivo </div> : null}

                                        {pedido.tipo_pago == 4 ? <div><i className="fa-solid fa-wifi"></i> <br></br> POS </div> : null}


                                      </div></td>
                                      <td >
                                        <div style={{ width: "100px" }} className="nameNumber text-center">
                                          {pedido.tipo_documento == 1 ? <i className="fa-solid fa-receipt text-success"></i> : <i className="fa-solid fa-file-invoice-dollar text-primary"></i>}
                                          {pedido.tipo_documento == 1 ? <span className="mb-1 ms-2">Boleta</span> : <span className="mb-1 ms-2">Factura</span>}
                                          <br />
                                          {pedido.url_pdf ?
                                            <a href={pedido.url_pdf} target="_blank">
                                              <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="btnGreen" style={{
                                                backgroundColor: "#0d6efd"
                                              }}>
                                                <i className="fa-solid fa-eye"></i>
                                              </button></a>
                                            : null
                                          }
                                        </div>
                                      </td>
                                    </>
                                    : null
                                  }

                                  <td >
                                    {estado == 1 ?
                                      <div style={{ width: "250px" }} className="nameNumber text-center">
                                        <>
                                          <div className="col-lg-12 col-md-12 col-12 text-center">
                                            <label htmlFor="">Pago</label>
                                            <select data-set-id={pedido.compra_pdv_id} aria-readonly={pedido.estado_pago == 3 ? "true" : "false"} className={pedido.estado_pago == 3 ? "form-select rounded-pill disabled disabled-select readonly bg-light" : "form-control rounded-pill"} onChange={cambiarEstado}>
                                              <option value="0" disabled>Seleccione una opción</option>
                                              {pedido.estado_pago == 1 ? <option value="1" selected>Pendiente de aprobación</option> : <option value="1" >Pendiente de aprobación</option>}
                                              {pedido.estado_pago == 2 ? <option value="2" selected>Aprobad@</option> : <option value="2" >Aprobad@</option>}
                                              {pedido.estado_pago == 3 ? <option value="4" selected>Anular pedido</option> : <option value="4" >Anular pedido</option>}
                                            </select>
                                          </div>
                                        </>

                                      </div>
                                      :
                                      <>
                                        {pedido.estado == 3 ?
                                          <>
                                            <div style={{ width: "200px" }} className="col-12 text-center nameNumber">
                                              <i className="fa-solid fa-person-through-window" style={{ color: "red" }}></i>
                                              <br />Abandonado
                                            </div>
                                          </>
                                          : null}
                                        {pedido.estado == 4 ?
                                          <>
                                            <div style={{ width: "200px" }} className="col-12 text-center nameNumber">
                                              <i className="fa-solid fa-shop-slash text-dark"></i>
                                              <br />Anulado</div>
                                          </>
                                          : null
                                        }
                                      </>
                                    }
                                  </td>
                                </tr>

                              ))}
                            </tbody>
                          </table>

                        ) : (


                          <div className="row my-5">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                              <i className="fa-solid fa-circle-info"></i>&nbsp;No hay pedidos registrados
                            </div>
                          </div>

                        )}

                      </div>
                    </div>
                  </div>
                ) : (
                  <>

                    <div className="col-12">
                      <div className="wrapper1">
                        <div className="wrapper2">

                          {pedidosAdmin.length > 0 ? (

                            <table className="table border">

                              <thead className="p-4">
                                <tr>
                                  <th className="col"><div className="id">Id</div></th>
                                  <th className="col"><div className="id">Detalle Compra</div></th>
                                  <th className="col"><div className="id">F. Compra</div></th>
                                  <th className="col"><div className="id">Dirección</div></th>
                                  <th className="col"><div className="id">Documento</div></th>
                                  <th className="col"><div className="id">Total</div></th>
                                  <th className="col"><div className="id">Datos de Cliente</div></th>
                                  <th className="col"><div className="id">Tipo Pago</div></th>
                                  <th className="col"><div className="id">Estado Pago</div></th>
                                  <th className="col"><div className="id">Estado Despacho</div></th>
                                  <th className="col"><div className="id">Foto entrega</div></th>
                                  <th className="col"><div className="id">Acciones</div></th>
                                </tr>
                              </thead>

                              <tbody>
                                {pedidosAdmin.map((pedido) => (

                                  <tr key={pedido.compra_pdv_id}>
                                    <td><div style={{ width: "100px" }} className="nameNumber text-center">{pedido.compra_pdv_id}</div></td>
                                    <td><div style={{ width: "200px" }} className="nameNumber text-center align-self-center">
                                      <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="btnGreen" style={{
                                        backgroundColor: "#0d6efd"
                                      }}
                                        onClick={() => { mostrarDetalleCompra(pedido.detalle_compra) }}><i className="fa-solid fa-eye"></i>
                                      </button>
                                    </div></td>

                                    <td><div style={{ width: "150px" }} className="nameNumber text-center">{getFecha(pedido.fecha_compra)}</div></td>

                                    <td ><div style={{ width: "200px" }} className="nameNumber text-center">

                                      {pedido.direccion == 'Dreamtec Tienda' ? (
                                        'Retiro en tienda'
                                      ) : (

                                        <>
                                          {pedido.direccion}
                                          <br></br>
                                          {pedido.nombre_comuna}
                                          <br />
                                          {pedido.nombre_region}
                                        </>

                                      )}

                                      <div className="mt-1"><strong>{pedido.horario_pedido}</strong></div>

                                    </div></td>

                                    <td>
                                      <div style={{ width: "100px" }} className="nameNumber text-center">
                                        {pedido.tipo_documento == 1 ? <i className="fa-solid fa-receipt text-success"></i> : <i className="fa-solid fa-file-invoice-dollar text-primary"></i>}
                                        {pedido.tipo_documento == 1 ? <span className="mb-1 ms-2">Boleta</span> : <span className="mb-1 ms-2">Factura</span>}
                                        {pedido.numero_documento == 0 || pedido.numero_documento == null ? "" : <div>N°: <strong>{pedido.numero_documento}</strong></div>}
                                        <br />
                                        {pedido.url_pdf ?
                                          <a href={pedido.url_pdf} target="_blank">
                                            <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="btnGreen" style={{
                                              backgroundColor: "#0d6efd"
                                            }}>
                                              <i className="fa-solid fa-eye"></i>
                                            </button></a>
                                          : null
                                        }
                                      </div>
                                    </td>
                                    <td><div style={{ width: "150px" }} className="nameNumber text-center">{"$" + Intl.NumberFormat('es-CL').format(pedido.total)}</div></td>

                                    <td><div style={{ width: "150px" }} className="nameNumber text-center">

                                      {pedido.first_name} {pedido.last_name}
                                      <div>{pedido.rut}</div>

                                    </div></td>
                                    {/* <td>
                                      <div style={{ width: "150px" }} className="nameNumber text-center">{pedido.tipo_tarjeta}</div>
                                    </td> */}
                                    <td>
                                      <div style={{ width: "150px" }} className="nameNumber text-center">

                                        {pedido.tipo_pago == 1 ? <div><i className="fa-solid fa-building-columns"></i> <i className="fa-solid fa-money-bill-transfer text-success"></i> <i className="fa-solid fa-building-columns"></i> <br></br> Transferencia</div> : null}

                                        {pedido.tipo_pago == 2 ? <div><i className="fa-solid fa-handshake" style={{ color: "#0450FF" }}></i> <br></br> Mercado Pago</div> : null}

                                        {pedido.tipo_pago == 3 ? <div><i className="fa-solid fa-money-bill"></i> <br></br> Efectivo </div> : null}

                                        {pedido.tipo_pago == 4 ?
                                          <div>
                                            <i className="fa-solid fa-wifi"></i> <br></br> POS <br />
                                            {pedido.numero_operacion ? <div>N°: <strong>{pedido.numero_operacion}</strong></div> : null}
                                          </div>
                                          : null
                                        }


                                      </div>
                                    </td>

                                    {/* <td><div style={{ width: "150px" }} className="nameNumber text-center">{pedido.compra_pdv_id}</div></td> */}
                                    <td>
                                      <div style={{ width: "150px" }} className="nameNumber text-center">

                                        {pedido.estado == 1 ? (
                                          <>
                                            <i className="fa-solid fa-circle-pause text-warning"></i><br></br>
                                            <div>Pendiente <br></br> de aprobación</div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {pedido.estado == 2 && (
                                          <>
                                            <i className="fa-solid fa-circle-check text-success"></i><br></br>
                                            <div>Aprobada</div>
                                          </>
                                        )}
                                        {pedido.estado_pago == 3 && (
                                          <>
                                            <i className="fa-solid fa-shop-slash text-dark"></i><br></br>
                                            <div>Pedido abandonado</div>
                                          </>
                                        )}
                                        {pedido.estado_pago == 4 && (
                                          <>
                                            <i className="fa-solid fa-shop-slash text-dark"></i><br></br>
                                            <div>Pedido anulado</div>
                                          </>
                                        )}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="nameNumber text-center">
                                        {
                                          pedido.estado_despacho == 'Entregada' && (
                                            <>
                                              <i className="fa-solid fa-circle-check text-success"></i>
                                              <div><strong>Entregado</strong></div>
                                            </>
                                          )
                                        }
                                        {
                                          pedido.estado_despacho == 'Entregado en tienda' && (
                                            <>
                                              <i className="fa-solid fa-circle-check text-success"></i>
                                              <div><strong>Entregado en tienda</strong></div>
                                            </>
                                          )
                                        }
                                        {
                                          pedido.estado_despacho == 'En Camino' && (
                                            <>
                                              <i className="fa-solid fa-truck text-warning"></i>
                                              <div><strong>En Camino</strong></div>
                                            </>
                                          )
                                        }
                                        {
                                          pedido.estado_despacho == 'En Preparación' && (
                                            <>
                                              <i className="fa-solid fa-circle-pause text-danger"></i>
                                              <div><strong>En Preparación</strong></div>
                                              <div style={{ width: "150px" }} className="nameNumber text-center">
                                                <label htmlFor="">Chofer</label>
                                                <select data-set-id={pedido.compra_pdv_id} onChange={asignarChofer} className="form-control rounded-pill">
                                                  <option value="0">Asignar Chofer</option>
                                                  {
                                                    choferes.map((choferes) => {
                                                      if (pedido.id_chofer == choferes.id)
                                                        return <option value={choferes.id} selected>{choferes.first_name} {choferes.last_name}</option>
                                                      else
                                                        return <option value={choferes.id}>{choferes.first_name} {choferes.last_name}</option>
                                                    })
                                                  }
                                                </select>
                                              </div>
                                            </>
                                          )
                                        }
                                        {
                                          pedido.estado_despacho == 'Venta Recibida' && (

                                            <>
                                              <i className="fa-solid fa-circle-pause text-primary"></i>
                                              <div><strong>Venta Recibida</strong></div>
                                            </>

                                          )}
                                      </div>
                                    </td>

                                    <td >
                                      <div style={{ width: "150px" }} className="nameNumber text-center">
                                        {pedido.foto_entrega == null || pedido.foto_entrega == '' ? <div><strong>N/A</strong></div> : (
                                          <div style={{ width: "150px" }} className="nameNumber text-center align-self-center">
                                            <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="agregar border-0 btnCelesteDreamtec text-white"
                                              onClick={() => { cargaFoto(pedido.foto_entrega) }}><i className="fa-solid fa-camera"></i>
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </td>

                                    <td>
                                      {estado == 2 ?
                                        <div style={{ width: "250px" }} className="nameNumber text-center">
                                          <>
                                            <div className="col-lg-12 col-md-12 col-12 text-center">
                                              <label htmlFor="">Pago</label>
                                              <select data-set-id={pedido.compra_pdv_id} aria-readonly={pedido.estado_pago == 3 ? "true" : "false"} className={pedido.estado_pago == 3 ? "form-select rounded-pill disabled disabled-select readonly bg-light" : "form-control rounded-pill"} onChange={cambiarEstado} defaultValue={pedido.estado}>
                                                <option value="0" disabled>Seleccione una opción</option>
                                                {pedido.estado_pago == 1 ? <option value="1" selected>Pendiente de aprobación</option> : <option value="1" >Pendiente de aprobación</option>}
                                                {pedido.estado_pago == 2 ? <option value="2" selected>Aprobad@</option> : <option value="2" >Aprobad@</option>}
                                                {pedido.estado_pago == 3 ? <option value="4" selected>Anular pedido</option> : <option value="4" >Anular pedido</option>}
                                              </select>
                                            </div>
                                          </>


                                          {pedido.estado != 3 && pedido.estado != 4 && (

                                            <>


                                              {pedido.direccion == 'Dreamtec Tienda' ? (
                                                <>
                                                  <div className="col-lg-12 col-md-12 col-12 text-center">
                                                    <label>Despacho</label>
                                                    <select className={pedido.estado == 2 ? "form-select rounded-pill" : "form-select rounded-pill disabled-select disabled readonly"} data-set-id={pedido.compra_pdv_id} onChange={cambiarDespacho}>
                                                      <option value="0" disabled>Seleccione una opción</option>
                                                      {pedido.estado_despacho == 'En Preparación' ? <option value="2" selected>En Preparación</option> : <option value="2" >En Preparación</option>}
                                                      {pedido.estado_despacho == 'Entregado en tienda' ? <option value="5" selected>Entregado en tienda</option> : <option value="5" >Entregado en tienda</option>}
                                                    </select>
                                                  </div>
                                                </>

                                              ) : (

                                                <>
                                                  <div className="col-lg-12 col-md-12 col-12 text-center">
                                                    <label>Despacho</label>
                                                    <select className={pedido.estado == 2 ? "form-select rounded-pill" : "form-select rounded-pill disabled-select disabled readonly"} data-set-id={pedido.compra_pdv_id} onChange={cambiarDespacho}>
                                                      <option value="0" disabled>Seleccione una opción</option>
                                                      {pedido.estado_despacho == 'En Preparación' ? <option value="2" selected>En preparación</option> : <option value="2" >En preparación</option>}
                                                      {pedido.estado_despacho == 'En Camino' ? <option value="3" selected>En Camino</option> : <option value="3" >En Camino</option>}
                                                      {pedido.estado_despacho == 'Entregada' ? <option value="4" selected>Entregado</option> : <option value="4" >Entregado</option>}
                                                    </select>
                                                  </div>
                                                </>

                                              )}

                                            </>


                                          )}

                                        </div>
                                        :
                                        null
                                      }
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                          ) : (


                            <div className="row my-5">
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                                <i className="fa-solid fa-circle-info"></i>&nbsp;No hay pedidos registrados
                              </div>
                            </div>

                          )}

                        </div>
                      </div>
                    </div>

                  </>
                )}

                <div className="row justify-content-end mt-3">
                  <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                    {currentPage > 1 && (
                      <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage - 1)}>
                        <i className="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                      </button>
                    )}

                  </div>

                  <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                    {currentPage + 1 <= totalPaginas && (

                      <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage + 1)}>
                        <i className="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                      </button>

                    )}


                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>


    </div>
  );
};

export default PuntoVenta;
