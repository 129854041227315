/**
 * Página Pago 1:
 * 
 * acá se muestra el paso 1 del carrito
 * 
 * @module PaginaPaso1jsx
 */

import React, { useEffect, useState } from "react";
import "./paso1.css";
import paso1 from "../assets/carrito/paso_1.png";


const Paso1 = () => {

  return (
    <div>
      <div className="container mt-3 mb-4" id="camino-de-migas">
        <div className="row justify-content-center">
          
        </div>
      </div>
      
      <div className="container mb-3" id="div-paso-1">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 px-0 text-center">
            <img
              src={paso1}
              className="img-fluid mx-auto"
              alt="Paso 1 Opciones de envío"
              title="Paso 1 Opciones de envío"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className="container my-5" id="div-cols-como-comprar">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12 col-12 text-center mb-5">
            <p>Compra en nuestro sitio usando tus datos o como invitado.</p>
          </div>
          <div className="col-lg-8 col-md-8 col-12">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mb-3">
                <div className="rounded border p-4 h-296">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 text-start">
                      <span className="fs-16 fw-bold">
                        Compra usando tus datos
                      </span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 text-start my-3">
                      <p>
                        Si estás registrado en dreamtec.cl inicia sesión para
                        una mejor experiencia.
                      </p>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 text-center mt-3">
                      <button
                        type="button"
                        id="continuar-registrado"
                        className="btn w-100 bg-turq btn-turq-hover py-2 text-white rounded-pill"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-inicio-sesion"
                        aria-label="Iniciar Sesión"
                      >
                        Iniciar Sesión
                      </button>
                    </div>
                  </div>
                </div>

                <div className="border rounded px-4 pt-3 mt-3">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 text-start">
                      <p className="mb-2">
                        Regístrate para una mejor experiencia
                      </p>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 text-center pb-4">
                      <button
                        type="button"
                        aria-label="Registrarme"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-registro"
                        className="btn w-100 bg-turq py-2 bg-turq btn-turq-hover text-white rounded-pill"
                      >
                        Registrarme
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              


              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mb-3">
                <div className="border rounded p-4 mb-2">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 text-start">
                      <span className="fs-16 fw-bold">
                        Comprar como invitado
                      </span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 text-start my-3">
                      <p>Puedes continuar con tu compra como invitado.</p>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 text-start">
                      <button
                        type="button"
                        id="continuar-registrado"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-sesion-invitado"
                        aria-label="Iniciar Sesión"
                        className="btn w-100 bg-turq py-2 bg-turq btn-turq-hover  text-white rounded-pill"
                      >
                        Continuar como invitado
                      </button>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paso1;
