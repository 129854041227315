/**
 * Página Admin Pedidos Bodega Web:
 * 
 * acá se Administran pedidos bodega Web
 * 
 * @module AdminPedidosBodegaWebjsx
 */


import { useState, useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ModalDialog from 'react-bootstrap/ModalDialog';
import parse from 'html-react-parser';
import Mensaje from "../../components/Modales/Mensaje";

import { AdminSidebar } from "../../components";

const PedidosBodegaWeb = () => {


  /**
* @constant
* @name foto
* @type {string}
* 
* */


  const [foto, setFoto] = useState('');

  /**
* @constant
* @name pedidos
* @type {Array}
* 
* */
  const [pedidos, setPedidos] = useState([]);

  /**
* @constant
* @name currentPage
* @type {number}
* 
* */
  const [currentPage, setCurrentPage] = useState(1);
  const [filtroPage, setFiltroPage] = useState(1);

  /**
* @constant
* @name show
* @type {boolean}
* 
* */
  const [show, setShow] = useState(false);

  /**
* @constant
* @name modal_body
* @type {string}
* 
* */
  const [modal_body, setModalBody] = useState('');

  /**
* @constant
* @name modalBodyRetiro
* @type {string}
* 
* */
  const [modalBodyRetiro, setModalBodyRetiro] = useState('');

  /**
* @constant
* @name showRetiro
* @type {boolean}
* 
* */
  const [showRetiro, setShowRetiro] = useState(false);

  /**
* @constant
* @name showFoto
* @type {boolean}
* 
* */
  const [showFoto, setShowFoto] = useState(false);

  /**
* @constant
* @name totalPaginas
* @type {string}
* 
* */
  const [totalPaginas, setTotalPaginas] = useState('');


  const [mesFiltro, setMesFiltro] = useState(parseInt(new Date().getMonth()) + 1);
  const [anioFiltro, setAnioFiltro] = useState(new Date().getFullYear());

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");
  let mesesLlenar = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  let aniosLlenar = [];

  const tipoConsulta = useRef(1);
  const queryId = useRef(1);
  const mostrando = useRef(1);

  const [textoFiltro, setTextoFiltro] = useState("");

  const handleCloseFoto = () => {
    setShowFoto(false);
  }

  const handleCloseRetiro = () => {
    setModalBodyRetiro('');
    setShowRetiro(false);
  }

  const handleClose = () => {
    setModalBody('');
    setShow(false);
  }

  const handleAnioChange = (e) => {
    setAnioFiltro(e.target.value);
  };

  const handleMesChange = (e) => {
    setMesFiltro(parseInt(e.target.value) + 1);
  };

  const llenarAnio = () => {
    const year = new Date().getFullYear();
    let retornar = "";
    for (let i = year; i >= 1900; i--) {
      aniosLlenar.push(i);
    }
  };

  const cargaFoto = (foto) => {
    setFoto(foto);
    handleShowFoto();
  }

  const filtrar = (e) => {
    obtenerPedidos();
  };

  const handleShowRetiro = () => setShowRetiro(true);
  const handleShowFoto = () => setShowFoto(true);
  const handleShow = () => setShow(true);


  /**
* @function
* Metodo crear una fecha en base al Objeto Date de Js
* @returns {string}
*/
  const getFecha = (fecha) => {

    let fecha_compra = new Date(fecha);
    let dia = fecha_compra.getDate();
    let mes = fecha_compra.getMonth() + 1;
    let anio = fecha_compra.getFullYear();

    let hour = fecha_compra.getHours();
    let minutes = fecha_compra.getMinutes();
    let seg = fecha_compra.getSeconds();

    dia = dia <= 9 ? '0' + dia : dia;
    mes = mes <= 9 ? '0' + mes : mes;
    minutes = minutes <= 9 ? '0' + minutes : minutes;
    seg = seg <= 9 ? '0' + seg : seg;

    return `${dia}-${mes}-${anio} ${hour}:${minutes}:${seg}`;

  }

  /**
* @function
* Metodo para mostrar el detalle de la compra en un modal
* @param {json} detalle_compra Objeto que contiene el detalle de la compra
* @returns {string}
*/
  const mostrarDetalleCompra = (detalle_compra) => {

    let detalles = detalle_compra;
    let dc = '';

    detalles.map((index) => {

      let row = '';

      row = `
    
        <div className="row justify-content-center text-center">
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                <span>${index.sku}</span>
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                <img loading="lazy" width="100px" height="auto" src="${process.env.REACT_APP_FRONT_URL + index.foto_1}" aria-label="${index.producto_nombre}">
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                ${index.producto_nombre}
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                ${index.cantidad}
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                $${Intl.NumberFormat('es-CL').format(index.precio_unitario * index.cantidad)}
            </div>

            <div className="col-10 text-center">
              <hr>
            </div>

        </div>
      
        `;

      dc += row;

    })

    setModalBody(dc);

    handleShow();

  }



  /**
* @function
* Metodo para mostrar el detalle de la persona quien retira en un modal
* @param {json} persona_retiro Objeto que contiene los datos de la persona quien retira
* @returns {string}
*/
  const mostrarDetalleRetiro = (persona_retiro) => {
    let p_retiro = persona_retiro;

    let row = '';

    row = `
      
          <div className="row justify-content-center text-center">
              <div className="col-lg-3 col-md-3 col-12 text-center align-self-center">
                ${p_retiro.nombre_retiro} ${p_retiro.apellido_retiro.replace(".zzzzz.", "")}
              </div>
              <div className="col-lg-3 col-md-3 col-12 text-center align-self-center">
                <a href="mailto:${p_retiro.email_retiro}" aria-label="Correo" className="text-decoration-none text-dark text-break">${p_retiro.email_retiro}</a> 
              </div>
              <div className="col-lg-3 col-md-3 col-12 text-center align-self-center">
                 ${p_retiro.rut_retiro}
              </div>
              <div className="col-lg-3 col-md-3 col-12 text-center align-self-center">
                 <a href="tel:${p_retiro.telefono_retiro}" className="text-decoration-none text-dark" aria-label="Teléfono">${p_retiro.telefono_retiro}</a> 
              </div>
          </div>
        
          `;


    setModalBodyRetiro(row);

    handleShowRetiro();

  }


  /**
* @function
* Petición HTTP que obtiene los pedidos filtrados por mes y año 
* @param {number} currentPage Pagina actual
* @param {string} anioFiltro Año para filtrar los pedidos
* @param {string} mesFiltro Mes para filtror los pedidos
* @returns {Array}
*/
  const obtenerPedidos = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACK_URL + `/api/admin/listar_pedidos_inicial_bodega_web?page=${currentPage}&size=10&anio_filtro=${anioFiltro}&mes_filtro=${mesFiltro}`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {

      setPedidos(response.data.results);
      setTotalPaginas(response.data.pages);

    }).catch(error => {
      console.log(error);
    })
  };

  useEffect(() => {
    if (textoFiltro != "") {
      const response = axios.get(
        process.env.REACT_APP_BACK_URL + `/api/admin/listar_pedidos_inicial_bodega_web?filtroPage=${filtroPage}&size=10&retorno=1&anio_filtro=${anioFiltro}&mes_filtro=${mesFiltro}&textoFiltro=${textoFiltro}&queryId=${queryId.current}`, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      }).then(response => {
        if (parseInt(response.data.queryId) >= parseInt(mostrando.current)) {
          setPedidos(response.data.results);
          setTotalPaginas(response.data.pages);
          tipoConsulta.current = 2;
          mostrando.current = response.data.queryId;
        }
      }).catch(error => {
        console.log(error);
      })
      queryId.current = queryId.current + 1;
    }
  }, [textoFiltro, filtroPage]);

  const accessToekn = sessionStorage.getItem("access");


  /**
* @function
* Petición HTTP que cambia el estado del despacho de una compra
* @param {string} despacho Estado de despacho
* @param {string} id_pedido Id del pedido
* @returns {Array}
*/
  const cambiarDespacho = (e) => {
    let id_pedido = e.target.getAttribute('data-set-id');
    let despacho = e.target.value;

    e.preventDefault();
    setMensaje("Cambiando el despacho-alert");
    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/cambiar_estado_compra?despacho=${despacho}&compra=${id_pedido}`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {

      obtenerPedidos();
      setMensaje("Despacho cambiado-verde");
    }).catch(error => {
      setMensaje("Error al cambiar el despacho-rojo");
    })
  }

  useEffect(() => {

    obtenerPedidos();


  }, [currentPage]);

  const clearFiltro = () => {
    setTextoFiltro("");
    setFiltroPage(1);
    setCurrentPage(1);
    obtenerPedidos();
    queryId.current = 1;
    mostrando.current = 1;
  };

  return (
    <div>
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <Modal show={showFoto} onHide={handleCloseFoto} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className=" container" >

            <div className="row justify-content-center text-center col-12 mb-4">
              <div className="col-12 text-center align-self-center">
                {foto != "" ? (
                  <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + foto} alt="" id="fotoMostrar" className="img-fluid" />
                ) : (
                  <div><strong>N/A</strong></div>
                )}
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>


      <Modal show={showRetiro} onHide={handleCloseRetiro} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center mb-5">

              <div className="col-lg-3 col-md-3 col-12 text-center">
                <strong>Nombre Completo</strong>
              </div>

              <div className="col-lg-3 col-md-3 col-12 text-center">
                <strong>Correo</strong>
              </div>

              <div className=" col-lg-3 col-md-3 col-12 text-center">
                <strong>Rut</strong>
              </div>

              <div className="col-lg-3 col-md-3 col-12 text-center">
                <strong>Teléfono</strong>
              </div>
            </div>
          </div>

          <div className=" container" >
            {modalBodyRetiro != '' ? (
              parse(modalBodyRetiro)
            ) : (

              <div className="row my-5">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                  <i className="fa-solid fa-circle-info"></i>&nbsp;No hay detalle de la persona quien retira
                </div>
              </div>

            )}
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center mb-5">

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>SKU</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Foto</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Nombre</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Cantidad</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Sub total</strong>
              </div>
            </div>
          </div>
          <div className=" container" >
            {modal_body != '' ? (
              parse(modal_body)
            ) : (

              <div className="row my-5">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                  <i className="fa-solid fa-circle-info"></i>&nbsp;No hay detalle del pedido
                </div>
              </div>

            )}
          </div>

        </Modal.Body>
      </Modal>


      <AdminSidebar />

      <div className="regular">
        <h1>Dreamtec - <span>Administrador de pedidos web para bodega</span></h1>

        <div className="shadow">

          <div className="row">

            <div className="col-12 text-center mb-4">
              <h2>Listado Pedidos Web</h2>
            </div>
            <div className="col-12">

              <div className="row d-flex justify-content-center mb-4 mt-1">
                <div className="col-lg-2 col-md-4 col-12 text-center mt-1">
                  <select id='anioSelect' onChange={handleAnioChange} className="form-select rounded-pill">
                    {llenarAnio()}
                    {
                      aniosLlenar.map((mesesLlenar) => {
                        return <option value={mesesLlenar}>{mesesLlenar}</option>
                      })
                    }
                  </select>
                </div>

                <div className="col-lg-2 col-md-4 col-12 text-center mt-1 mb-4">
                  <select id="mesSelect" onChange={handleMesChange} className="form-select rounded-pill">
                    {
                      mesesLlenar.map((mesesLlenar, index) => {
                        if ((index + 1) == mesFiltro) {
                          return <option value={index} selected>{mesesLlenar}</option>
                        }
                        else {
                          return <option value={index}>{mesesLlenar}</option>
                        }
                      })}
                  </select>
                </div>

                <div className="col-lg-2 col-md-4 col-12 mt-1">
                  <button className="agregar border-0 btnCelesteDreamtec text-white " onClick={filtrar} aria-label="filtrar">Filtrar</button>
                </div>

                <div className="row text-end justify-content-end mt-3">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-5">
                    <div className="input-group buscador">
                      <input
                        type="text"
                        className="form-control input-search border-end-0"
                        value={textoFiltro}
                        onChange={(e) => setTextoFiltro(e.target.value)}
                        placeholder="Filtrar por: Nombre, Rut, Correo, Teléfono, Compra o N° Documento"
                        style={{ borderStartStartRadius: "20px", borderEndStartRadius: "20px", borderRight: "0px" }}
                      />

                      <span role="button"
                        className="bg-transparent input-group-text border-start-0"
                        onClick={clearFiltro} style={{ borderEndEndRadius: "20px", borderStartEndRadius: "20px" }}
                      >
                        <i className="fa fa-filter-circle-xmark"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {pedidos.length > 0 ? (

                <>
                  <div className="wrapper1">
                    <div className="wrapper2">

                      <table className="table border">
                        <thead className="p-4">
                          <tr><th className="col" style={{ width: "100px" }}><div className="id">ID</div></th>
                            <th className="col" style={{ width: "200px" }}><div className="id">Detalle compra</div></th>
                            <th className="col" style={{ width: "200px" }}><div className="id">F. Compra</div></th>
                            <th className="col" style={{ width: "200px" }}><div className="id">Dirección</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Tipo Documento</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Total</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Datos de cliente</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Persona retiro</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">F. Entrega</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Contacto cliente</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Tipo Pago</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Estado Pago</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Estado de Despacho</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Foto entrega</div></th>
                            <th className="col" style={{ width: "250px" }}><div className="id">Acciones</div></th>
                          </tr></thead>
                        <tbody>
                          {pedidos
                            ? pedidos.map((pedido) => (

                              <tr key={pedido.id}>
                                <td ><div style={{ width: "100px" }} className="nameNumber text-center align-self-center">{pedido.id}</div></td>
                                <td ><div style={{ width: "200px" }} className="nameNumber text-center align-self-center">
                                  <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="btnGreen" style={{
                                    backgroundColor: "#0d6efd"
                                  }}
                                    onClick={() => { mostrarDetalleCompra(pedido.detalle_compra) }}><i className="fa-solid fa-eye"></i>
                                  </button>
                                </div></td>
                                <td ><div style={{ width: "200px" }} className="nameNumber text-center">{getFecha(pedido.fecha)}</div></td>
                                <td ><div style={{ width: "200px" }} className="nameNumber text-center">

                                  {pedido.direccion == 'Dreamtec Tienda' ? (
                                    'Retiro en tienda'
                                  ) : (

                                    <>
                                      {pedido.direccion}
                                      <br></br>
                                      {pedido.nombre_comuna}
                                      <br />
                                      {pedido.nombre_region}
                                    </>

                                  )}

                                  <div className="mt-1"><strong>{pedido.horario_pedido}</strong></div>

                                </div></td>
                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">
                                  <a href={pedido.url_pdf} target="_blank" className="text-decoration-none text-dark" aria-label="Ver Documento">

                                    {pedido.tipo_documento == 1 ? <i className="fa-solid fa-receipt text-success"></i> : <i className="fa-solid fa-file-invoice-dollar text-primary"></i>}
                                    {pedido.tipo_documento == 1 ? <span className="mb-1 ms-2">Boleta</span> : <span className="mb-1 ms-2">Factura</span>}

                                  </a>

                                  {pedido.estado_pago != 3 ? (

                                    <>
                                      {pedido.numero_documento == 0 || pedido.numero_documento == null ? "" : <div>N° D <strong>{pedido.numero_documento}</strong></div>}
                                    </>

                                  ) : (

                                    <>

                                    </>

                                  )}

                                </div></td>
                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">{"$" + Intl.NumberFormat('es-CL').format(pedido.total)}</div></td>

                                <td >
                                  <div style={{ width: "150px" }} className="nameNumber text-center">
                                    {pedido.apellido && pedido.apellido != ".zzzzz." ? (
                                      pedido.nombre + " " + pedido.apellido
                                    ) : (
                                      pedido.nombre
                                    )}
                                    <br />
                                    {pedido.rut}
                                  </div>
                                </td>
                                <td ><div style={{ width: "150px" }} className="nameNumber text-center"><button type="button" aria-label="Ver persona quien retira" className="btnGreen btnCelesteDreamtec"
                                  onClick={() => { mostrarDetalleRetiro(pedido.persona_retiro) }}><i className="fa-solid fa-box-open"></i></button></div></td>
                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">{pedido.fecha_entrega != null ? getFecha(pedido.fecha_entrega) : "Sin fecha de entrega"}</div></td>
                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">
                                  {pedido.email != '' && (
                                    <a className="text-decoration-none text-dark" href={"mailto:" + pedido.email}>{pedido.email}</a>
                                  )}
                                  {pedido.email != '' ? <br /> : null}
                                  {pedido.telefono != '' && (
                                    <a className="text-decoration-none text-dark" href={"tel:+" + pedido.telefono}>{pedido.telefono}</a>
                                  )}
                                </div></td>
                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">
                                  {pedido.metodo_pago == 'Transferencia' && (
                                    <div><i className="fa-solid fa-money-bill-transfer text-success"></i></div>
                                  )}

                                  {pedido.metodo_pago == 'Tarjeta Débito' || pedido.metodo_pago == 'Mercado Pago' || pedido.metodo_pago == 'Tarjeta Crédito' || pedido.metodo_pago == 'Webpay' ? <div><i className="fa-solid fa-credit-card" style={{ color: "#0450FF" }}></i></div> : null}

                                  {pedido.metodo_pago}
                                </div></td>
                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">
                                  {/* 0 es pendiente de aprobación desde mercado pago sin retorno , 1 tambien que seria transferencia */}

                                  {pedido.estado_pago == 0 || pedido.estado_pago == 1 ? (
                                    <>
                                      <i className="fa-solid fa-circle-pause text-warning"></i><br></br>
                                      <div>Pendiente <br></br> de aprobación</div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {pedido.estado_pago == 2 && (
                                    <>
                                      <i className="fa-solid fa-circle-check text-success"></i><br></br>
                                      <div>Aprobada</div>
                                    </>
                                  )}
                                  {pedido.estado_pago == 3 && (
                                    <>
                                      <i className="fa-solid fa-shop-slash text-dark"></i><br></br>
                                      <div>Pedido anulado</div>
                                    </>
                                  )}
                                </div></td>

                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">

                                  {pedido.estado_pago != 3 ? (

                                    <>
                                      {
                                        pedido.estado_despacho == 'Venta Recibida' && (

                                          <>
                                            <i className="fa-solid fa-circle-pause text-primary"></i>
                                            <div><strong>Venta Recibida</strong></div>
                                          </>

                                      )}
                                      {
                                        pedido.estado_despacho == 'En Preparación' && (
                                          <>
                                            <i className="fa-solid fa-circle-pause text-danger"></i>
                                            <div><strong>En Preparación</strong></div>
                                          </>
                                        )
                                      }
                                      {
                                        pedido.estado_despacho == 'En Camino' && (
                                          <>
                                            <i className="fa-solid fa-truck text-warning"></i>
                                            <div><strong>En Camino</strong></div>
                                          </>
                                        )
                                      }
                                      {
                                        pedido.estado_despacho == 'Entregada' && (
                                          <>
                                            <i className="fa-solid fa-circle-check text-success"></i>
                                            <div><strong>Entregado</strong></div>
                                          </>
                                        )
                                      }
                                      {
                                        pedido.estado_despacho == 'Entregado en tienda' && (
                                          <>
                                            <i className="fa-solid fa-circle-check text-success"></i>
                                            <div><strong>Entregado en tienda</strong></div>
                                          </>
                                        )
                                      }

                                    </>

                                  ) : (

                                    <>
                                      <div><strong>N/A</strong></div>
                                    </>

                                  )}

                                </div></td>

                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">
                                  {pedido.foto_entrega == null || pedido.foto_entrega == '' ? <div><strong>N/A</strong></div> : (
                                    <div style={{ width: "150px" }} className="nameNumber text-center align-self-center">
                                      <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="agregar border-0 btnCelesteDreamtec text-white"
                                        onClick={() => { cargaFoto(pedido.foto_entrega) }}><i className="fa-solid fa-camera"></i>
                                      </button>
                                    </div>
                                  )}
                                </div></td>
                                <td ><div style={{ width: "250px" }} className="nameNumber text-center">




                                  {pedido.estado_pago != 3 && (

                                    <>


                                      {pedido.direccion == 'Dreamtec Tienda' ? (
                                        <>

                                          <div className="col-lg-12 col-md-12 col-12 text-center">
                                            <label>Despacho</label>
                                            <select className={pedido.estado_pago == 2 ? "form-select rounded-pill" : "form-select rounded-pill disabled-select disabled readonly"} data-set-id={pedido.id} onChange={cambiarDespacho}>
                                              <option value="0" disabled>Seleccione una opción</option>
                                              {pedido.estado_despacho == 'En Preparación' ? <option value="2" selected>En Preparación</option> : <option value="2" >En Preparación</option>}
                                              {pedido.estado_despacho == 'En Camino' ? <option value="3" selected>En Camino</option> : <option value="3" >En Camino</option>}
                                              {pedido.estado_despacho == 'Entregado en tienda' ? <option value="5" selected>Entregado en tienda</option> : <option value="5" >Entregado en tienda</option>}
                                            </select>
                                          </div>

                                        </>

                                      ) : (


                                        <>


                                          <div className="col-lg-12 col-md-12 col-12 text-center">
                                            <label>Despacho</label>
                                            <select className={pedido.estado_pago == 2 ? "form-select rounded-pill" : "form-select rounded-pill disabled-select disabled readonly"} data-set-id={pedido.id} onChange={cambiarDespacho}>
                                              <option value="0" disabled>Seleccione una opción</option>
                                              {pedido.estado_despacho == 'En Preparación' ? <option value="2" selected>En preparación</option> : <option value="2" >En preparación</option>}
                                              {pedido.estado_despacho == 'En Camino' ? <option value="3" selected>En Camino</option> : <option value="3" >En Camino</option>}
                                              {pedido.estado_despacho == 'Entregado' ? <option value="4" selected>Entregado</option> : <option value="4" >Entregado</option>}
                                            </select>
                                          </div>



                                        </>

                                      )}

                                    </>


                                  )}

                                </div>
                                </td>
                              </tr>

                            ))
                            : ""}
                        </tbody>
                      </table>

                    </div>
                  </div>

                  <div className="row justify-content-end mt-3">
                  <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                      {tipoConsulta.current == 1 && (
                        <>
                          {currentPage > 1 && (
                            <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage - 1)}>
                              <i className="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                            </button>
                          )}
                        </>
                      )}
                      {tipoConsulta.current == 2 && (
                        <>
                          {filtroPage > 1 && (
                            <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setFiltroPage(filtroPage - 1)}>
                              <i className="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                      {tipoConsulta.current == 1 && (
                        <>
                          {currentPage + 1 <= totalPaginas && (

                            <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => {
                              setCurrentPage(currentPage + 1)
                            }}>
                              <i className="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                            </button>

                          )}
                        </>
                      )}
                      {tipoConsulta.current == 2 && (
                        <>
                          {filtroPage + 1 <= totalPaginas && (

                            <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => {
                              setFiltroPage(filtroPage + 1)
                            }}>
                              <i className="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                            </button>

                          )}
                        </>
                      )}

                    </div>
                  </div>

                </>

              ) : (

                <div className="row my-5">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                    <i className="fa-solid fa-circle-info"></i>&nbsp;No hay pedidos web registrados
                  </div>
                </div>

              )}

            </div>

          </div>

        </div>
      </div>





    </div >
  );

};

export default PedidosBodegaWeb;
