/**
 * Página Contacto:
 * 
 * acá puede enviar un correo hacia Dreamtec
 * 
 * @module PaginaContactojsx
 */

import { useEffect, useState } from "react";
import React from "react";
import "./contacto.css";
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../redux/fechaEntregaSlice";
import { setComunaSelected } from "../redux/comunaSelectedSlice";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import htmlReactParser from 'html-react-parser';

const Contacto = () => {

  /**
 * @constant
 * @name userAlready
 * @type {string}
 * 
 * */

  const [userAlready] = useState(sessionStorage.getItem("IDusuario"));

  /**
 * @constant
 * @name tipoEnvio
 * @type {number}
 * 
 * */
  const [showAgregado, setShowAgregado] = useState(false);
  const [name, setName] = useState("");
  const [empresa, setEmpresa] = useState("");
  const tipoEnvio = useSelector((state) => state.tipoEnvio);
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [icono, setIcono] = useState("error");

  const dispatch = useDispatch();

  const [mensajeError, setMensajeError] = useState("");
  const handleShowAgregado = () => setShowAgregado(true);
  const handleCloseAgregado = () => {
    setShowAgregado(false);
  }

  const keyupNombre = (e) => {
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
    e.target.value = e.target.value.replace(/\d/g, '');
  }

  const keyupEmpresa = (e) => {
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
    e.target.value = e.target.value.replace(/\d/g, '');
  }

  const keyupTelefono = (e) => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.substr(0, 10);
    }
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
    e.target.value = e.target.value.replace(/\,\-\+/g, '');
  }

  /**
* @function
* Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
* validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
* @param {string} userAlready Id del usuario
* @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
*@returns {Array}
* 
*/
  useEffect(() => {
    if (userAlready && tipoEnvio !== 4) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tipoEnvio]);

  const mostrarError = (msj) => {
    setMensajeError(msj);
    handleShowAgregado();
  };

  const validaDatos = () => {
    var validCorrreo = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validTelefono = /^([0-9])*$/;
    var validarHTML = /<("[^"]*"|'[^']*'|[^'">])*>/;

    if (name == "") {
      mostrarError("Complete nombre y apellido");
      setIcono("error");
      return false;
    }
    if (email == "") {
      mostrarError("Complete el correo");
      setIcono("error");
      return false;
    }
    if (mensaje == "") {
      mostrarError("Ingrese el mensaje");
      setIcono("error");
      return false;
    }

    if(validarHTML.test(name)){
      mostrarError("Debe ingresar un nombre y apellido válido");
      setIcono("error");
      return false;
    }
    if (empresa != "") {
      if(validarHTML.test(empresa)){
        mostrarError("Ingrese un nombre de empresa válido");
        setIcono("error");
        return false;
      }
    }
    if(!validCorrreo.test(email)){
      mostrarError("Email no válido");
      setIcono("error");
      return false;
    }
    if (number == "" || !validTelefono.test(number)) {
      mostrarError("Telefono no válido");
      setIcono("error");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validaDatos()){
      const formData = new FormData();
      formData.append("nombre", name);
      formData.append("empresa", empresa);
      formData.append("correo", email);
      formData.append("telefono", number);
      formData.append("mensaje", mensaje);

      axios
        .post(process.env.REACT_APP_BACK_URL + "/api/contacto/correo_contacto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("res");
          console.log(res);
          setIcono("check");
          mostrarError(res.data.message);
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
          setIcono("error");
          mostrarError(err.data.message);
        });
    }
  };

  return (

    <>
      <Helmet>
        <link rel="canonical" href={process.env.REACT_APP_FRONT_URL + "/contacto"} />
      </Helmet>
      <Modal show={showAgregado} onHide={handleCloseAgregado} centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-3 text-center bg-black py-3">
                {icono == "check" ?
                  <>
                    <i class="fa-solid fa-circle-check text-success fs-1 mb-3 d-block"></i>
                  </>
                  :
                  <>
                    <i className="fa-solid fa-circle-xmark text-turq fs-1 mb-3 d-block"></i>
                    <span className="fs-6 text-white mt-1" id="titulo_error">ERROR</span>
                  </>
                }
              </div>
              <div className="col-lg-10 col-md-10 col-11 mb-2 text-center">
                <p className="fs-14 text-black mb-0" id="texto_error" >{htmlReactParser(mensajeError)}</p>
              </div>
              <div className="col-lg-6 col-md-7 col-8 bg-turq" style={{ height: "3px" }}>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
      <div className="container mt-3 mb-4">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-11 px-1">
            <a className="fs-14 text-black text-decoration-none" href="/">Inicio</a>
            <span className="text-black"><i className="fa-solid fa-angle-right px-2 fs-12"></i></span>
            <span className="fw-bold fs-6 text-turq">Contacto</span>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-9 col-sm-10 col-11">
            <form className="">
              <div className="row justify-content-center bg-white px-2 py-5 border rounded">

                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">

                  <label
                    htmlFor="nombreyapellido"
                    className="fs-14 text-black mb-2"
                  >
                    Nombre y Apellido <b className="text-danger">*</b>
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    id="nombreyapellido"
                    aria-describedby="nombreyapellido"
                    placeholder="Ej: Dreamtec Empresa"
                    onKeyUp={keyupNombre}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                </div>

                <div className="col-lg-4 col-md-5 col-sm-12 col-12 mb-3">

                  <label htmlFor="nombreempresa" className="fs-14 text-black mb-2">
                    Nombre Empresa (Opcional)
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    id="nombreempresa"
                    aria-describedby="nombreyapellido"
                    placeholder="Ej: Empresa ejemplo"
                    onKeyUp={keyupEmpresa}
                    value={empresa}
                    onChange={(e) => setEmpresa(e.target.value)}
                  />

                </div>

                <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 mb-3">

                  <label htmlFor="InputEmail1" className="fs-14 text-black mb-2">
                    Correo electrónico <b className="text-danger">*</b>
                  </label>
                  <input
                    type="email"
                    className="form-control rounded-pill"
                    id="InputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Ej: dreamtec@mail.cl"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div id="emailHelp" className="form-text">
                    Nunca compartiremos tu correo con nadie más.
                  </div>

                </div>

                <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-3">

                  <label
                    htmlFor="nombreyapellido"
                    className="fs-14 text-black mb-2"
                  >
                    Teléfono <b className="text-danger">*</b>
                  </label>
                  <input
                    type="number"
                    id="telefono_registro"
                    className="form-control rounded-pill"
                    placeholder="Ej: 987654321"
                    maxLength={10}
                    minLength={10}
                    onKeyUp={keyupTelefono}
                    onInput={keyupTelefono}
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />

                </div>

                <div className="col-lg-10 col-md-10 col-sm-12 col-12 mb-3">

                  <label htmlFor="mensaje" className="fs-14 text-black mb-2">
                    Mensaje <b className="text-danger">*</b>
                  </label>
                  <textarea
                    className="form-control resize"
                    placeholder="Escribe aquí un mensaje que quieras dejarnos"
                    id="mensaje"
                    maxLength={1000}
                    rows={5}
                    style={{ height: '200px' }}
                    value={mensaje}
                    onChange={(e) => setMensaje(e.target.value)}
                  ></textarea>

                </div>


                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-8 col-12 text-center d-grid">
                  <button
                    type="submit"
                    className="btn-borde-turq text-turq rounded-pill py-1 px-2"
                    onClick={handleSubmit}
                  >
                    Enviar Formulario
                  </button>
                </div>



              </div>
            </form>
          </div>
        </div>
      </div>
    </>

  );
};

export default Contacto;
