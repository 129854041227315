import { Routes, Route } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Home,
  Cart,
  Shop,
  ProductDetail,
  Checkout,
  Terminas,
  Contacto,
  ProductPage,
  NotFound,
  Confirmed,
  Paso1,
  AdminLogin,
  DashBoard,
  Tarifas,
  Usuarios,
  PuntoVenta,
  Pedidos,
  Personal,
  Productos,
  ProductAdd,
  ProductUpdate,
  Banners,
  Paso2,
  Paso3,
  GrandesMarcas,
  BannersCategorias,
  Perfil,
  ComprasAnteriores,
  Garantias,
  Preguntas,
  PedidosBodegaWeb,
  PedidosChofer,
  PedidosAsignados,
  PersonalAdmin,
  Marcas,
  Search,
  CategoryPage,
  CategoriasCRUD,
  CategoriaPadrePage,
  BrandPage,
  Category,
  PedidosBodegaPDV,
  PedidosChoferPDV,
  Mantencion,
  LogTransacciones,
  LoUltimoEn
} from "../pages";
import axios from "axios";

const Routers = () => {
  const [categoriaState, setCategoriaState] = useState("");
  const esCategoria = useSelector((state) => state.booleanCategoria);
  const esBusqueda = useSelector((state) => state.boolean);
  const redirect = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname != "/") {
      const regex = /\/(.+)/;
      const match = regex.exec(pathname);
      const categoria = match[1];
      setCategoriaState(categoria);
    }
  }, [esCategoria, esBusqueda, categoriaState]);


  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/mantencion/checkmantencion`, {

      })
      .then((res) => {
        if (res.data.ip == "G7WX3nx1vJ.hGSMgVSdYEu0CDu0x6B3jgTd2xvjtiQVGOZIshPDYm")
          navigate("/mantencion");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [redirect]);

  const categorias = [
    "audifonos",
    "accesorios-gamer-zone",
    "sillas-gamer",
    "monitores",
    "camaras-web",
    "accesorios-sillas-gamer",
    "notebooks",
    "accesorios-consolas",
    "consolas",
    "insumos-impresora",
    "escaner",
    "impresoras",
    "video-proyector",
    "accesorios-home-office",
    "monitores",
    "notebooks",
    "all-in-one",
    "desktop",
    "aspiradoras",
    "tablets",
    "maquinas-de-coser",
    "smart-tv",
    "batidoras",
    "soundbar",
    "parlantes",
    "accesorios-smart-home",
    "accesorios",
    "computadores",
    "simuladores",
  ];


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:Slug" element={<Category />} />
      <Route path="/:Padre/:Hija" element={<CategoryPage />} />
      <Route path="/loultimoen/:Hija" element={<LoUltimoEn />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/search" element={<Search />} />
      <Route path="/perfil" element={<Perfil />} />
      <Route path="/compras" element={<ComprasAnteriores />} />

      <Route path="/checkout" element={<Checkout />} />
      <Route path="/confirmed" element={<Confirmed />} />
      <Route path="/paso1" element={<Paso1 />} />
      <Route path="/paso2" element={<Paso2 />} />
      <Route path="/paso3" element={<Paso3 />} />
      <Route path="/mantencion" element={<Mantencion />} />

      {/* rutas footer */}
      <Route path="/terminos" element={<Terminas />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="/garantias" element={<Garantias />} />
      <Route path="/faq" element={<Preguntas />} />
      {/* rutas footer */}

      {/* rutas de administrador */}
      <Route path="/admin" element={<AdminLogin />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin/dashboard" element={<DashBoard />} />
      <Route path="/admin/tarifas" element={<Tarifas />} />
      <Route path="/admin/usuarios" element={<Usuarios />} />
      <Route path="/admin/puntoventa" element={<PuntoVenta />} />
      <Route path="/admin/pedidos" element={<Pedidos />} />
      <Route path="/admin/personal" element={<Personal />} />
      <Route path="/admin/productos" element={<Productos />} />
      <Route path="/admin/productos/agregar" element={<ProductAdd />} />
      <Route path="/admin/productos/actualizar" element={<ProductUpdate />} />
      <Route path="/admin/banners" element={<Banners />} />
      <Route path="/admin/grandesmarcas" element={<GrandesMarcas />} />
      <Route path="/admin/bannercategorias" element={<BannersCategorias />} />
      <Route path="/admin/pedidosbodegaweb" element={<PedidosBodegaWeb />} />
      <Route path="/admin/pedidoschofer" element={<PedidosChofer />} />
      <Route path="/admin/pedidoschoferpdv" element={<PedidosChoferPDV />} />
      <Route path="/admin/pedidosasignados" element={<PedidosAsignados />} />
      <Route path="/admin/personaladmin" element={<PersonalAdmin />} />
      <Route path="/admin/marcas" element={<Marcas />} />
      <Route path="/admin/categorias" element={<CategoriasCRUD />} />
      <Route path="/admin/pedidosbodegapdv" element={<PedidosBodegaPDV />} />
      <Route path="/admin/transacciones" element={<LogTransacciones />} />

      {/* rutas de administrador */}

      <Route path="*" element={<NotFound />} status={404} />
    </Routes>
  );
};

export default Routers;
