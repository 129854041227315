/**
 * Componente Categorias:
 * 
 * acá se los desplegables por categorias padres
 * 
 * @module ComponenteCategoriasjsx
 */

import { useState, useEffect } from "react";
import axios from "axios";
import "./categorias.css";
import { useNavigate } from "react-router-dom";

import logocat1 from "../../assets/categorias/icono-cat-gamer-zone.png";
import logocat2 from "../../assets/categorias/icono-cat-home-office.png";
import logocat3 from "../../assets/categorias/icono-cat-smart-home.png";

import { useDispatch } from "react-redux";
import { setBooleanCategoria } from "../../redux/esCategoriaSlice";

const Categorias = () => {


  /**
* @constant
* @name categoriasSmartHome
* @type {Array}
* 
* */
  const [categoriasSmartHome, setCategoriasSmartHome] = useState([]);

  /**
* @constant
* @name categoriasGamerZone
* @type {Array}
* 
* */
  const [categoriasGamerZone, setCategoriasGamerZone] = useState([]);

  /**
* @constant
* @name categoriasHomeOffice
* @type {Array}
* 
* */
  const [categoriasHomeOffice, setCategoriasHomeOffice] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
* @function
* Petición HTTP para obtener todas las categorias hijas por padres
* @param {string} userAlready Id del usuario logueado
* @returns {Array}
* 
*/
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/categorias/get_all_categorias")
      .then((res) => {
        setCategoriasGamerZone(res.data.gamer_zone);
        setCategoriasHomeOffice(res.data.home_office);
        setCategoriasSmartHome(res.data.smart_home);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="">
      {" "}
      <div className="row text-center p-0">
        <label className="form-label fw-bold mt-3" style={{ fontSize: "25px" }}>
          CATEGORÍAS
        </label>
        <div className=" justify-content-end row-categorias-lateral">
          <div className="col-12 px-0">


            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header accordion-header-cat mt-1"
                  id="flush-headingOne1"
                >
                  <button
                    className="accordion-button accordion-button-cat collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <img
                      loading="lazy"
                      src={logocat3}
                      width="32px"
                      alt="Categoría Smart Home"
                    />
                    <span className="ps-2 text-turq fw-bold fs-6">
                      Smart Home
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne1"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body accordion-body-cat">
                    <div className="row justify-content-center hooverturq">
                      <div>
                        {categoriasSmartHome && categoriasSmartHome.map((categoria, index) => (
                          <div
                            onClick={() => {
                              location.href = `/smart-home/${categoria}`;
                              dispatch(setBooleanCategoria(index + 1));
                            }}
                            className="py-1 cat-lateral"
                            key={index}
                          >
                            {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header accordion-header-cat"
                  id="flush-headingOne2"
                >
                  <button
                    className="accordion-button accordion-button-cat collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne2"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne2"
                  >
                    <img
                      loading="lazy"
                      src={logocat2}
                      width="32px"
                      alt="Categoría Smart Home"
                    />
                    <span className="ps-2 text-turq fw-bold fs-6">
                      Home Office
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne2"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne2"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body accordion-body-cat">
                    <div className="row justify-content-center hooverturq">
                      {categoriasHomeOffice && categoriasHomeOffice.map((categoria, index) => (
                        <div
                          className="py-1 cat-lateral"
                          onClick={() => {
                            location.href = `/home-office/${categoria}`;
                            dispatch(setBooleanCategoria(index + 1));
                          }}
                          key={index}
                        >
                          {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header accordion-header-cat"
                  id="flush-headingOne3"
                >
                  <button
                    className="accordion-button accordion-button-cat collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne3"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne3"
                  >
                    <img
                      loading="lazy"
                      src={logocat1}
                      width="32px"
                      alt="Categoría Smart Home"
                    />
                    <span className="ps-2 text-turq fw-bold fs-6">
                      Gamer Zone
                    </span>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne3"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne3"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body accordion-body-cat">
                    <div className="row justify-content-center hooverturq">
                      {categoriasGamerZone && categoriasGamerZone.map((categoria, index) => (
                        <div
                          onClick={() => {
                            location.href = `/gamer-zone/${categoria}`;
                            dispatch(setBooleanCategoria(index + 1));
                          }}
                          className="py-1 cat-lateral"
                          key={index}
                        >
                          {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default Categorias;
