/**
 * Página Admin ProductAdd:
 * 
 * acá se ingresar un nuevo producto
 * 
 * @module AdminProductAddjsx
 */


import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';

import "../../assets/summernote/summernote-lite.min.css";
import "../../assets/summernote/summernote-lite.min.js";
import "../../assets/summernote/font/summernote.eot";
import "../../assets/summernote/font/summernote.ttf";
import "../../assets/summernote/font/summernote.woff2";
import "../../assets/summernote/font/summernote.woff";

const ProductAdd = (props) => {
  const navigate = useNavigate();

  /**
* @constant
* @name marcas
* @type {null}
* 
* */
  const [marcas, setMarcas] = useState();

  /**
* @constant
* @name tallas
* @type {null}
* 
* */
  const [tallas, setTallas] = useState();

  /**
* @constant
* @name check_mercado_libre
* @type {boolean}
* 
* */
  const [check_mercado_libre, setCheck_mercado_libre] = useState(false);


  /**
* @constant
* @name check_paris
* @type {boolean}
* 
* */
  const [check_paris, setCheck_paris] = useState(false);

  /**
* @constant
* @name check_ripley
* @type {boolean}
* 
* */
  const [check_ripley, setCheck_ripley] = useState(false);


  /**
* @constant
* @name mercadolibre_categorias
* @type {Array}
* 
* */
  const [mercadolibre_categorias, setMercadolibre_categorias] = useState([]);

  /**
* @constant
* @name selected_mercadolibre_categoria
* @type {string}
* 
* */
  const [selected_mercadolibre_categoria, setSelected_mercadolibre_categoria] = useState("");

  /**
* @constant
* @name paris_familias
* @type {Array}
* 
* */
  const [paris_familias, setParis_familias] = useState([]);


  /**
* @constant
* @name selected_paris_familias
* @type {string}
* 
* */
  const [selected_paris_familias, setSelected_paris_familias] = useState("");



  /**
* @constant
* @name paris_categorias
* @type {Array}
* 
* */
  const [paris_categorias, setParis_categorias] = useState([]);

  /**
* @constant
* @name selected_paris_categoria
* @type {string}
* 
* */
  const [selected_paris_categoria, setSelected_paris_categoria] = useState("");


  /**
* @constant
* @name ripley_categorias
* @type {Array}
* 
* */
  const [ripley_categorias, setRipley_categorias] = useState([]);

  /**
* @constant
* @name selected_ripley_categoria
* @type {string}
* 
* */
  const [selected_ripley_categoria, setSelected_ripley_categoria] = useState("");


  /**
* @constant
* @name inputs
* @type {Array}
* 
* */
  const [inputs, setInputs] = useState([]);


  /**
* @constant
* @name inputs_paris
* @type {Array}
* 
* */
  const [inputs_paris, setInputs_paris] = useState([]);

  /**
* @constant
* @name inputs_ripley
* @type {Array}
* 
* */
  const [inputs_ripley, setInputs_ripley] = useState([]);


  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");

  /**
* @constant
* @name data_caracteritica
* @type {string}
* 
* */
  const [data_caracteritica, setData_caracteritica] = useState("");


  /**
* @constant
* @name data_short
* @type {string}
* 
* */

  const [data_short, setData_short] = useState("");

  /**
* @constant
* @name data_descripcion
* @type {string}
* 
* */

  const [data_descripcion, setData_descripcion] = useState("");


  /**
* @constant
* @name foto_1
* @type {null}
* 
* */
  const foto_1 = useRef(null);


  /**
* @constant
* @name foto_2
* @type {null}
* 
* */
  const foto_2 = useRef(null);


  /**
* @constant
* @name foto_3
* @type {null}
* 
* */
  const foto_3 = useRef(null);

  /**
* @constant
* @name foto_4
* @type {null}
* 
* */
  const foto_4 = useRef(null);

  /**
* @constant
* @name foto_5
* @type {null}
* 
* */
  const foto_5 = useRef(null);

  /**
* @constant
* @name foto_6
* @type {null}
* 
* */
  const foto_6 = useRef(null);


  const foto_1_src = useRef(null);


  /**
* @constant
* @name foto_1_src
* @type {null}
* 
* */
  const foto_2_src = useRef(null);

  /**
* @constant
* @name foto_2_src
* @type {null}
* 
* */
  const foto_3_src = useRef(null);

  /**
* @constant
* @name foto_3_src
* @type {null}
* 
* */
  const foto_4_src = useRef(null);

  /**
* @constant
* @name foto_4_src
* @type {null}
* 
* */
  const foto_5_src = useRef(null);

  /**
* @constant
* @name foto_5_src
* @type {null}
* 
* */
  const foto_6_src = useRef(null);

  /**
* @constant
* @name foto_6_src
* @type {null}
* 
* */

  const handleInputChange = (index, event) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  const handleInputChangeParis = (index, event) => {
    const values = [...inputs_paris];
    values[index].value = event.target.value;
    setInputs_paris(inputs_paris);
  };

  const handleInputChangerRipley = (index, event) => {
    const values = [...inputs_ripley];
    values[index].value = event.target.value;
    setInputs_ripley(inputs_ripley);
  };

  /**
* @constant
* @name productName
* @type {string}
* 
* */
  const [productName, setProductName] = useState("");

  /**
* @constant
* @name sku
* @type {string}
* 
* */
  const [sku, setSku] = useState("");

  /**
* @constant
* @name brand
* @type {string}
* 
* */
  const [brand, setBrand] = useState("");

  /**
* @constant
* @name price
* @type {string}
* 
* */
  const [price, setPrice] = useState("");

  /**
* @constant
* @name discountPrice
* @type {string}
* 
* */
  const [discountPrice, setDiscountPrice] = useState("");


  /**
* @constant
* @name stock
* @type {string}
* 
* */
  const [stock, setStock] = useState("");

  /**
* @constant
* @name slug
* @type {string}
* 
* */
  const [slug, setSlug] = useState("");


  /**
* @constant
* @name selectedSize
* @type {string}
* 
* */
  const [selectedSize, setSelectedSize] = useState("");

  /**
* @constant
* @name upc
* @type {string}
* 
* */
  const [upc, setUpc] = useState("");

  /**
* @constant
* @name pn
* @type {string}
* 
* */
  const [pn, setPn] = useState("");

  /**
* @constant
* @name categoria_padre
* @type {Array}
* 
* */
  const [categoria_padre, setCategoria_padre] = useState([]);

  /**
* @constant
* @name selected_categoria_padre
* @type {Array}
* 
* */

  const [selected_categoria_padre, setSelected_categoria_padre] = useState([]);

  /**
* @constant
* @name categoria_hija
* @type {Array}
* 
* */
  const [categoria_hija, setCategoria_hija] = useState([]);

  /**
* @constant
* @name selected_categoria_hija
* @type {Array}
* 
* */
  const [selected_categoria_hija, setSelected_categoria_hija] = useState([]);

  /**
* @constant
* @name msg
* @type {string}
* 
* */
  const [msg, setMsg] = useState("");

  /**
* @constant
* @name tipo
* @type {string}
* 
* */
  const [tipo, setTipo] = useState("");


  const accessToekn = sessionStorage.getItem("access");

  /**
* @constant
* @name foto1
* @type {null}
* 
* */
  const [foto1, setFoto1] = useState(null);

  /**
* @constant
* @name foto2
* @type {null}
* 
* */
  const [foto2, setFoto2] = useState(null);


  /**
* @constant
* @name foto3
* @type {null}
* 
* */
  const [foto3, setFoto3] = useState(null);


  /**
* @constant
* @name foto4
* @type {null}
* 
* */
  const [foto4, setFoto4] = useState(null);

  /**
* @constant
* @name foto5
* @type {null}
* 
* */
  const [foto5, setFoto5] = useState(null);


  /**
* @constant
* @name foto6
* @type {null}
* 
* */
  const [foto6, setFoto6] = useState(null);
  const [normalPrice, setNormalPrice] = useState("");

  const handle_check_mercado_libre = () => {
    setCheck_mercado_libre(!check_mercado_libre);
  };

  const handle_check_paris = () => {
    setCheck_paris(!check_paris);
  };

  const handle_check_ripley = () => {
    setCheck_ripley(!check_ripley);
  };

  useEffect(() => {

    /**
* @function
* Petición HTTP que obtiene todas las tallas de los productos
* @returns {Array}
*/

    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/listar_todas_tallas_productos`)
      .then((response) => {
        setTallas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
* @function
* Petición HTTP que obtiene todas las marcas
* @returns {Array}
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/marca/get_all_marcas`)
      .then((response) => {
        setMarcas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
* @function
* Petición HTTP que obtiene las familias
* @returns {Array}
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_familias`)
      .then((response) => {
        setParis_familias(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
* @function
* Petición HTTP que obtiene las categorias de ripley
* @returns {Array}
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/ripley/get_categorias`)
      .then((response) => {
        setRipley_categorias(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
* @function
* Petición HTTP que obtiene todas las categorias
* @returns {Array}
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria`)
      .then((response) => {
        setCategoria_padre(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);

  useEffect(() => {

    /**
* @function
* Petición HTTP que obtiene todas las categorias hijas por padre seleccionado
* @returns {Array}
*/

    if (selected_categoria_padre !== "") {
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria_hija_by_padre/${selected_categoria_padre}`)
        .then((response) => {
          setCategoria_hija(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCategoria_hija([]);
    }
  }, [selected_categoria_padre]);



  /**
* @function
* Petición HTTP que obtiene la categoria de mercado libre por el nombre del producto
* @param {string} productNanme Nombre del producto para buscar la categoria de mercado libre
* @returns {Array}
*/
  useEffect(() => {
    if (productName !== "") {
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/mercado_libre/get_categoria_mercado_libre_by_search/${productName}`)
        .then((response) => {
          setMercadolibre_categorias(response.data);
          setInputs([])
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMercadolibre_categorias([]);
    }
  }, [productName]);

  /**
* @function
* Petición HTTP que obtiene los atributos categoria de mercado libre por el Id de categoria
* @param {string} category_id Id de categoria 
* @returns {Array}
*/
  useEffect(() => {
    if (selected_mercadolibre_categoria !== "") {
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/mercado_libre/get_atributos_categoria_mercado_libre/${mercadolibre_categorias[0].category_id}`)
        .then((response) => {
          return response.data
        })
        .then((input) => {
          setInputs(input)
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMercadolibre_categorias([]);
    }
  }, [selected_mercadolibre_categoria]);

  /**
* @function
* Petición HTTP que obtiene las categorias por codigo de familia
* @param {string} selected_paris_familias familia paris seleccionada
* @returns {Array}
*/
  useEffect(() => {
    if (selected_paris_familias !== "") {
      setParis_categorias([]);
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_categorias_by_code/${selected_paris_familias}`)
        .then((response) => {
          return response.data
        })
        .then((input) => {
          setParis_categorias(input)
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setParis_categorias([]);
    }
  }, [selected_paris_familias]);

  /**
* @function
* Petición HTTP que obtiene los atributos por familia
* @param {string} selected_paris_familias familia paris seleccionada
* @returns {Array}
*/
  useEffect(() => {
    if (selected_paris_familias !== "") {
      setInputs_paris([]);
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_atributos_by_familia/${selected_paris_familias}`)
        .then((response) => {
          return response.data
        })
        .then((input) => {
          setInputs_paris(input)
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setInputs_paris([]);
    }
  }, [selected_paris_familias]);

  /**
* @function
* Petición HTTP que obtiene los atributos por familia
* @param {string} selected_ripley_categoria familia ripley seleccionada
* @returns {Array}
*/

  useEffect(() => {
    if (selected_ripley_categoria !== "") {
      setInputs_ripley([]);
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/ripley/get_atributos?hierarchy=${selected_ripley_categoria}`)
        .then((response) => {
          return response.data
        })
        .then((input) => {
          setInputs_ripley(input)
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setInputs_ripley([]);
    }
  }, [selected_ripley_categoria]);

  const setFotosNuevas = (id, foto) => {
    let img = document.getElementById("fotoMostrar" + id);
    let input = document.getElementById("foto" + id);

    if (foto != null && foto != "") {
      img.src = URL.createObjectURL(foto);
      if (id == 1) {
        setFoto1(foto);
      }
      if (id == 2) {
        setFoto2(foto);
      }
      if (id == 3) {
        setFoto3(foto);
      }
      if (id == 4) {
        setFoto4(foto);
      }
      if (id == 5) {
        setFoto5(foto);
      }
      if (id == 6) {
        setFoto6(foto);
      }
    }
  };

  const log = () => {
    //console.log(data_descripcion.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
  };

  const logC = () => {
    //console.log(data_caracteritica.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
  };

  const logS = () => {
    //console.log(data_short.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
  };



  /**
* @function
* Petición HTTP que guarda el nuevo producto
* @param {Object} formData formData con todos los datos del producto
* @returns {Array}
*/


  const handleSubmit = (e) => {
    e.preventDefault();
    setMensaje("Creando producto-alert");
    setMsg("");
    setTipo(0);
    let des = parseInt(discountPrice);
    let tac = parseInt(normalPrice);
    let dif = (tac - des) * 100 / tac;
    if (dif <= 10) {
      setMsg("El precio tachado debe ser más de 10% superior al precio descuento");
      setMensaje("El precio tachado debe ser más de 10% superior al precio descuento-rojo");
      return false;
    }


    const formData = new FormData();
    formData.append("name", productName);
    formData.append("sku", sku.toUpperCase());
    formData.append("description", data_descripcion.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("short", data_short.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("marcas_id", brand);
    formData.append("precio_descuento", discountPrice);
    formData.append("stock", stock);
    formData.append("slug", slug);
    formData.append("caracteristicas", data_caracteritica.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("talla_producto", selectedSize);
    formData.append("upc", upc);
    formData.append("pn", pn);
    formData.append("categoria", selected_categoria_padre);
    formData.append("categoria_hija", selected_categoria_hija);
    formData.append("precio_tachado", normalPrice);
    formData.append("foto_1", foto1);
    if (foto2 != null) {
      formData.append("foto_2", foto2);
    }
    if (foto3 != null) {
      formData.append("foto_3", foto3);
    }
    if (foto4 != null) {
      formData.append("foto_4", foto4);
    }
    if (foto5 != null) {
      formData.append("foto_5", foto5);
    }
    if (foto6 != null) {
      formData.append("foto_6", foto6);
    }

    axios
      .post(
        process.env.REACT_APP_BACK_URL + "/api/admin/producto/register_producto",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        }
      )
      .then((response) => {
        setMsg("Producto agregado correctamente");
        setMensaje("Producto creado correctamente-verde");
        if (check_mercado_libre) {
          const jsonFormattedData = {
            title: productName,
            category_id: "MLC3530", //categoria otra por defecto, cambiar selected_mercadolibre_categoria
            precio: price,
            listing_type_id: "bronze",
            foto_1: 'https://img.freepik.com/foto-gratis/amor-romance-perforado-corazon-papel_53876-87.jpg',
            stock: stock
          };

          const dataToSend = {
            valores: inputs.map((values) => {
              return { name: values.name, value: values.value };
            }),
          };

          dataToSend.valores.forEach((obj) => {
            jsonFormattedData[obj.name] = obj.value;
          });

          axios
            .post(
              process.env.REACT_APP_BACK_URL + "/api/admin/mercado_libre/register_producto_mercado_libre",
              jsonFormattedData, {}
            )
            .then((response) => {

            })
            .catch((error) => {
              console.error(error);
            });
        }
        if (check_paris) {
          const jsonFormattedDataParis = {
            nombre: productName,
            sku: sku,
            familia: selected_paris_familias,
            category: selected_paris_categoria,
            precio: parseInt(price),
            foto_1: process.env.REACT_APP_FRONT_URL + response.data.fotos[0].ruta,
          };

          const dataToSendParis = {
            valores: inputs_paris.map((values) => {
              return { name: values.attribute_id, value: values.value };
            }),
          };

          dataToSendParis.valores.forEach((obj) => {
            jsonFormattedDataParis[obj.name] = obj.value;
          });

          axios
            .post(
              process.env.REACT_APP_BACK_URL + "/api/admin/paris/register_producto",
              jsonFormattedDataParis, {}
            )
            .then((response) => {

            })
            .catch((error) => {
              console.error(error);
            });
        }


        if (check_ripley) {
          const jsonFormattedDataRipley = {
            categorias: selected_ripley_categoria,
            state: "11",
            sku: sku,
            "product-id": sku,
            "product-id-type": "SKU",
            imagen1: process.env.REACT_APP_FRONT_URL + response.data.fotos[0].ruta,
            thumbnail: process.env.REACT_APP_FRONT_URL + response.data.fotos[0].ruta,
          };

          const dataToSendRipley = {
            valores: inputs_ripley.map((values) => {
              return { name: values.code, value: values.value };
            }),
          };

          dataToSendRipley.valores.forEach((obj) => {
            jsonFormattedDataRipley[obj.name] = obj.value;
          });

          axios
            .post(
              process.env.REACT_APP_BACK_URL + "/api/admin/ripley/get_csv",
              jsonFormattedDataRipley, {}
            )
            .then((response) => {

            })
            .catch((error) => {
              console.error(error);
            });
        }


        setTipo(1);

        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);

        setTimeout(() => {
          props.setAccion(1);
        }, 5000);

        setProductName("");
        setSku("");
        setBrand("");
        setPrice("");
        setDiscountPrice("");
        setStock("");
        setSlug("");
        setSelectedSize("");
        setUpc("");
        setPn("");
        setCheck_mercado_libre(false);
        setMercadolibre_categorias([]);
        setSelected_mercadolibre_categoria("");
        foto_1.current.value = null;
        foto_2.current.value = null;
        foto_3.current.value = null;
        foto_4.current.value = null;
        foto_5.current.value = null;
        foto_6.current.value = null;

        foto_1_src.current.src = "";
        foto_2_src.current.src = "";
        foto_3_src.current.src = "";
        foto_4_src.current.src = "";
        foto_5_src.current.src = "";
        foto_6_src.current.src = "";

      })
      .catch((error) => {
        console.error(error);
        setMensaje("Error al crear el producto, intente nuevamente-rojo");
        setMsg("No se pudo agregar el producto, intente nuevamente");
        setTipo(2);

        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);
      });


  };


  $('#summernote').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 1000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 1000) {
          maxPaste = 1000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_caracteritica(e);
      }
    }
  });

  $('#summernote2').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 50000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 50000) {
          maxPaste = 50000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_descripcion(e);
      }
    }
  });

  $('#summernote3').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 10000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 10000) {
          maxPaste = 10000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_short(e);
      }
    }
  });

  const setProductName2 = (texto) => {
    setProductName(texto);
    setSlug(limpiaSlug(texto))
  };

  const setSlug2 = (texto) => {
    let vari = limpiaSlug(texto);
    setSlug(vari);
  };

  const limpiaSlug = (text) => {
    let tildes = ['á', 'é', 'í', 'ó', 'ú', 'Á', 'É', 'Í', 'Ó', 'Ú', 'ñ', 'Ñ'];
    let reemp = ['a', 'e', 'i', 'o', 'u', 'a', 'e', 'i', 'o', 'u', 'n', 'n'];
    let borrar = ['!', '?', '¿', '¡', '@', '.', ',', '"', "'", '$', '%', '&', '/', '(', ')', '=', '¡', '+', '*', '´', '¨', '{', '[', ']', ']', ';', ':', '_', '#', '°', '|', '}'];
    for (let i = 0; i < tildes.length; i++) {
      text = text.replaceAll(tildes[i], reemp[i]);
    }
    for (let i = 0; i < borrar.length; i++) {
      text = text.replaceAll(borrar[i], "");
    }
    text = text.replaceAll(" ", "-");
    text = text.toLowerCase();
    return text;
  };

  var imageUploadDiv = $('div.note-group-select-from-files');
  if (imageUploadDiv.length) {
    imageUploadDiv.remove();
  }

  return (


    <div>

      <AdminSidebar />

      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />

      <div className="regular">

        <h1>
          Dreamtec - <span>Administrador de productos</span>
        </h1>

        <div className="shadow">
          <div className="row">
            <div className="col-12 text-start my-3">
              <h2>Agregar Producto</h2>
            </div>

            <div className="col-12">
              <div className="row">
                <form onSubmit={handleSubmit}>
                  <div className="row">

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Nombre del producto: &nbsp;
                        {/* para mercado libre debe ser nombre completo (ej: monitor gamer 27 logitech) */}
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Ingresa nombre producto"
                        type="text"
                        value={productName}
                        required
                        onChange={(e) => setProductName2(e.target.value)}
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        SKU: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        placeholder="Ingresa SKU"
                        value={sku}
                        onChange={(e) => setSku(e.target.value.toUpperCase())}
                      />
                    </div>



                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Categoria: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <select
                        value={selected_categoria_padre}
                        onChange={(e) => setSelected_categoria_padre(e.target.value)}
                        className="form-control rounded-pill"
                        required
                      >
                        <option value="">Selecciona una categoria</option>
                        {categoria_padre.map((categoria) => {
                          if (categoria.id == 1 || categoria.id == 2 || categoria.id == 3) {
                            return <option key={categoria.id} value={categoria.id}>
                              {categoria.categoria.charAt(0).toUpperCase() + categoria.categoria.slice(1)}
                            </option>
                          }
                        })}
                      </select>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Categoria Hija: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <select
                        value={selected_categoria_hija}
                        onChange={(e) => setSelected_categoria_hija(e.target.value)}
                        className="form-control rounded-pill"
                        required
                      >
                        <option value="">Selecciona una categoria hija</option>
                        {categoria_hija.map((categoria) => (
                          <option key={categoria.id} value={categoria.id}>
                            {categoria.hija.charAt(0).toUpperCase() + categoria.hija.slice(1)}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Stock: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        required
                        placeholder="Ingresa Stock"
                        value={stock}
                        pattern="[0-9]{9}"
                        onChange={(e) => setStock(parseInt(e.target.value))}
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Slug: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        placeholder="Ingresa slug"
                        value={slug}
                        onChange={(e) => setSlug2(e.target.value)}
                      />
                    </div>

                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Características destacadas (máximo 1.000 caracteres): &nbsp;
                        <span className="text-danger">*</span>
                      </label>

                      <div id="summernote"></div>

                      {/* <button onClick={logC}>Log editor content</button> */}

                    </div>

                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Short (mini descripción, máximo 10.000 caracteres): &nbsp;
                        <span className="text-danger">*</span>
                      </label>

                      <div id="summernote3"></div>


                      {/* <button onClick={logS}>Log editor content</button> */}
                    </div>

                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Descripción (máximo 50.000 caracteres): &nbsp;
                        <span className="text-danger">*</span>
                      </label>

                      <div id="summernote2"></div>

                      {/* <button onClick={log}>Log editor content</button> */}
                    </div>



                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Marca: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <select
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                        className="form-control rounded-pill"
                        required
                      >
                        <option value="">Selecciona una marca</option>
                        {marcas?.map((marca) => (
                          <option key={marca.id} value={marca.id}>
                            {marca.nombre_mostrar}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Talla del producto: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        value={selectedSize}
                        onChange={(e) => setSelectedSize(e.target.value)}
                        className="form-control rounded-pill"
                        required
                      >
                        <option value="">Selecciona una talla</option>
                        {tallas?.map((talla) => (
                          <option key={talla.id} value={talla.id}>
                            {talla.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Precio con descuento: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        pattern="[0-9]{9}"
                        value={discountPrice}
                        onChange={(e) =>
                          setDiscountPrice(parseFloat(e.target.value))
                        }
                        placeholder="Ingresa precio descuento"
                        required
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Precio normal (tachado): &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        pattern="[0-9]{9}"
                        value={normalPrice}
                        onChange={(e) =>
                          setNormalPrice(parseFloat(e.target.value))
                        }
                        placeholder="Ingresa precio normal"
                        required
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        UPC: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Ingresa UPC"
                        type="text"
                        value={upc}
                        required
                        onChange={(e) => setUpc(e.target.value)}
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        P/N: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        placeholder="Ingresa P/N"
                        value={pn}
                        onChange={(e) => setPn(e.target.value)}
                      />
                    </div>

                    <div className="row col-10">
                      <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                          <label>
                            Foto 1: &nbsp;<span className="text-danger">*</span>
                          </label>

                          <input
                            type="file"
                            id="foto1"
                            ref={foto_1}
                            accept="image/*"
                            onChange={(e) =>
                              setFotosNuevas(1, e.target.files[0])
                            }
                            className="form-control col-12"
                            required
                          />

                        </div>
                        <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                          <label htmlFor="fotoMostrar1" id="labelMostrar1">Foto Actual</label>
                          <img loading="lazy" ref={foto_1_src} src="" alt="" id="fotoMostrar1" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                        </div>
                      </div>

                      <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                        <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                          <label>
                            Foto 2:
                          </label>

                          <input
                            type="file"
                            id="foto2"
                            ref={foto_2}
                            accept="image/*"
                            onChange={(e) =>
                              setFotosNuevas(2, e.target.files[0])
                            }
                            className="form-control col-12"
                          />

                        </div>
                        <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                          <label htmlFor="fotoMostrar2" id="labelMostrar2">Foto Actual</label>
                          <img loading="lazy" ref={foto_2_src} src="" alt="" id="fotoMostrar2" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                        </div>
                      </div>
                    </div>

                    <div className="row col-10">
                      <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                          <label>
                            Foto 3:
                          </label>

                          <input
                            type="file"
                            id="foto3"
                            ref={foto_3}
                            accept="image/*"
                            onChange={(e) =>
                              setFotosNuevas(3, e.target.files[0])
                            }
                            className="form-control col-12"
                          />

                        </div>
                        <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                          <label htmlFor="fotoMostrar3" id="labelMostrar3">Foto Actual</label>
                          <img loading="lazy" ref={foto_3_src} src="" alt="" id="fotoMostrar3" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                        </div>
                      </div>

                      <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                        <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                          <label>
                            Foto 4:
                          </label>

                          <input
                            type="file"
                            id="foto4"
                            ref={foto_4}
                            accept="image/*"
                            onChange={(e) =>
                              setFotosNuevas(4, e.target.files[0])
                            }
                            className="form-control col-12"
                          />

                        </div>
                        <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                          <label htmlFor="fotoMostrar4" id="labelMostrar4">Foto Actual</label>
                          <img loading="lazy" ref={foto_4_src} src="" alt="" id="fotoMostrar4" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                        </div>
                      </div>
                    </div>

                    <div className="row col-10">
                      <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                          <label>
                            Foto 5:
                          </label>

                          <input
                            type="file"
                            id="foto5"
                            ref={foto_5}
                            accept="image/*"
                            onChange={(e) =>
                              setFotosNuevas(5, e.target.files[0])
                            }
                            className="form-control col-12"
                          />

                        </div>
                        <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                          <label htmlFor="fotoMostrar5" id="labelMostrar5">Foto Actual</label>
                          <img loading="lazy" ref={foto_5_src} src="" alt="" id="fotoMostrar5" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                        </div>
                      </div>

                      <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                        <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                          <label>
                            Foto 6:
                          </label>

                          <input
                            type="file"
                            id="foto6"
                            ref={foto_6}
                            accept="image/*"
                            onChange={(e) =>
                              setFotosNuevas(6, e.target.files[0])
                            }
                            className="form-control col-12"
                          />

                        </div>
                        <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                          <label htmlFor="fotoMostrar6" id="labelMostrar6">Foto Actual</label>
                          <img loading="lazy" ref={foto_6_src} src="" alt="" id="fotoMostrar6" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                        </div>
                      </div>
                    </div>

                    {/*<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mb-3">
                      <label className="mb-3 mt-2">
                        Mercado Libre:
                      </label>
                      <input
                        type="checkbox"
                        className="mx-2"
                        checked={check_mercado_libre}
                        onChange={handle_check_mercado_libre}
                      />
                    </div>



                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mb-3">
                      <label className="mb-3 mt-2">
                        Paris:
                      </label>
                      <input
                        type="checkbox"
                        className="mx-2"
                        checked={check_paris}
                        onChange={handle_check_paris}
                      />
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mb-3">
                      <label className="mb-3 mt-2">
                        Ripley:
                      </label>
                      <input
                        type="checkbox"
                        className="mx-2"
                        checked={check_ripley}
                        onChange={handle_check_ripley}
                      />
                    </div>*/}


                    {check_mercado_libre && (
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <label className="mb-3 mt-2">Mercado Libre</label>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
                            <label>
                              Categoria: &nbsp;<span className="text-danger">*</span>
                            </label>
                            <select
                              value={selected_mercadolibre_categoria}
                              onChange={(e) => setSelected_mercadolibre_categoria(e.target.value)}
                              className="form-control rounded-pill"
                              required
                            >
                              <option value="">Selecciona una categoria</option>
                              {mercadolibre_categorias?.map((categoria) => (
                                <option key={categoria.category_id} value={categoria.category_id}>
                                  {categoria.category_name}
                                </option>
                              ))}
                            </select>
                          </div>

                          {inputs.length > 0 && (
                            <>
                              {inputs.map((input, index) => (
                                <div key={index} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
                                  <label>
                                    {input.placeholder}: &nbsp;<span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={input.name}
                                    placeholder={input.placeholder}
                                    required
                                    onChange={(event) => handleInputChange(index, event)} />
                                </div>
                              ))}
                            </>
                          )}

                        </div>
                      </div>
                    )}

                    {check_paris && (
                      <div className="row">

                        <div className="col-12">
                          <label className="mb-3 mt-2">Paris</label>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                          <label>
                            Familia: &nbsp;<span className="text-danger">*</span>
                          </label>
                          <select
                            value={selected_paris_familias}
                            onChange={(e) => setSelected_paris_familias(e.target.value)}
                            className="form-control rounded-pill"
                            required
                          >
                            <option value="">Selecciona una Familia</option>
                            {paris_familias?.map((familia) => (
                              <option key={familia.id} value={familia.id}>
                                {familia.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                          <label>
                            Categiorias: &nbsp;<span className="text-danger">*</span>
                          </label>
                          <select
                            value={selected_paris_categoria}
                            onChange={(e) => setSelected_paris_categoria(e.target.value)}
                            className="form-control rounded-pill"
                            key="select_caterorias"
                            required
                          >
                            <option value="">Selecciona una Categoria</option>
                            {paris_categorias?.map((categoria, index) => (
                              <option key={index + categoria.id} value={categoria.id}>
                                {categoria.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {inputs_paris.length > 0 && (
                          <>
                            {inputs_paris.map((input, index) => (
                              <>
                                {input.values.length === 0 ? (
                                  <div key={index} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                    <label key={"label_" + input.id}>
                                      {input.name}: &nbsp;<span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      key={input.attribute_id}
                                      name={input.id}
                                      placeholder={input.name}
                                      required
                                      onChange={(event) => handleInputChangeParis(index, event)} />
                                  </div>
                                ) : (
                                  <div key={index} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                    <label key={"label_" + input.id}>
                                      {input.name}: &nbsp;<span className="text-danger">*</span>
                                    </label>
                                    <select
                                      key={input.id}
                                      onChange={(event) => handleInputChangeParis(index, event)}
                                      className="form-control rounded-pill"
                                      required
                                    >
                                      <option key={"select_" + input.id + "_" + index} value="">Selecciona una opcion</option>
                                      {input.values?.map((opciones, index2) => (
                                        <option key={index2 + "_" + opciones.id} value={opciones.id + "_" + input.type}>
                                          {opciones.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        )}

                      </div>
                    )}

                    {check_ripley && (
                      <div className="row">
                        <div className="col-12">
                          <label className="mb-3 mt-2">Ripley</label>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
                          <label>
                            Categoria: &nbsp;<span className="text-danger">*</span>
                          </label>
                          <select
                            value={selected_ripley_categoria}
                            onChange={(e) => setSelected_ripley_categoria(e.target.value)}
                            className="form-control rounded-pill"
                            key="categorias_ripley"
                            required
                          >
                            <option key="seleccione_categoria_ripley" value="">Selecciona una categoria</option>
                            {ripley_categorias.map((categoria, index) => (
                              <option key={"categoria_ripley_" + categoria.code} value={categoria.code}>
                                {categoria.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        {inputs_ripley.length > 0 && (
                          <>
                            {inputs_ripley.map((input, index) => (
                              <>
                                {input.values.length === 0 ? (
                                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                    <label >
                                      {input.label}: &nbsp;<span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={input.code}

                                      placeholder={input.label}
                                      required
                                      onChange={(event) => handleInputChangerRipley(index, event)} />
                                  </div>
                                ) : (
                                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                    <label>
                                      {input.label}: &nbsp;<span className="text-danger">*</span>
                                    </label>
                                    <select
                                      onChange={(event) => handleInputChangerRipley(index, event)}
                                      className="form-control rounded-pill"

                                      required
                                    >
                                      <option key="seleccione_opcion_ripley" value="">Selecciona una opcion</option>
                                      {input.values.map((opciones, index2) => (
                                        <option key={"option_ripley_" + opciones.code + "_" + index2} value={opciones.code}>
                                          {opciones.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        )}

                      </div>
                    )}

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <button
                        aria-label="Agregar producto"
                        className="btnGreen w-100"
                        type="submit"
                      >
                        Agregar Producto
                      </button>
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <button
                        aria-label="Agregar producto"
                        className="cancel w-100"
                        type="submit"
                        onClick={() => {
                          props.setAccion(1);
                        }}
                      >
                        Volver atrás
                      </button>
                    </div>
                  </div>

                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 my-3 text-center">
                    <span
                      className={
                        tipo == 1
                          ? "text-success fw-bold"
                          : "text-danger fw-bold"
                      }
                    >
                      {msg}
                    </span>
                  </div>

                </form>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default ProductAdd;
