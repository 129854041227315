/**
 * Componente NavCategorias:
 * 
 * acá se muestra el navbar de categorias
 * 
 * @module ComponenteNavCategoriasjsx
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./navcategorias.css";

import { useDispatch } from "react-redux";
import { setBoolean } from "../../redux/esBusquedaSlice";
import { setBooleanCategoria } from "../../redux/esCategoriaSlice";
import { setCategoriaPadre } from "../../redux/categoriapadreSlice";

import gamerzone from "../../assets/categorias/imagen-cat-gamer-zone-b.png";
import gamerzoneturq from "../../assets/categorias/imagen-cat-gamer-zone.png";
import homeoffice from "../../assets/categorias/imagen-cat-home-office-b.png";
import homeofficeturq from "../../assets/categorias/imagen-cat-home-office.png";
import smarthome from "../../assets/categorias/imagen-cat-smart-home-b.png";
import smarthometurq from "../../assets/categorias/imagen-cat-smart-home.png";

import subcat1 from "../../assets/categorias/icono-header-subcat-1.png";
import subcat2 from "../../assets/categorias/icono-header-subcat-2.png";
import subcat3 from "../../assets/categorias/icono-header-subcat-3.png";
import subcat4 from "../../assets/categorias/icono-header-subcat-4.png";
import subcat99 from "../../assets/categorias/icono-header-subcat-99.png";
import subcat6 from "../../assets/categorias/icono-header-subcat-6.png";
import subcat51 from "../../assets/categorias/icono-header-subcat-51.png";
import subcat8 from "../../assets/categorias/icono-header-subcat-8.png";
import subcat9 from "../../assets/categorias/icono-header-subcat-9.png";
import cyber from "../../assets/categorias/cyber.png"

const NavCategorias = () => {

  /**
* @constant
* @name gamerZoneHovered
* @type {boolean}
* 
* */
  const [gamerZoneHovered, setGamerZoneHovered] = useState(false);

  /**
* @constant
* @name homeOfficeHovered
* @type {boolean}
* 
* */
  const [homeOfficeHovered, setHomeOfficeHovered] = useState(false);

  /**
* @constant
* @name smartHomeHovered
* @type {boolean}
* 
* */
  const [smartHomeHovered, setSmartHomeHovered] = useState(false);


  /**
* @constant
* @name categoriasSmartHome
* @type {Array}
* 
* */
  const [categoriasSmartHome, setCategoriasSmartHome] = useState([]);

  /**
* @constant
* @name categoriasGamerZone
* @type {Array}
* 
* */
  const [categoriasGamerZone, setCategoriasGamerZone] = useState([]);

  /**
* @constant
* @name categoriasHomeOffice
* @type {Array}
* 
* */
  const [categoriasHomeOffice, setCategoriasHomeOffice] = useState([]);

  /**
* @constant
* @name gamerZoneVisible
* @type {boolean}
* 
* */
  const [gamerZoneVisible, setGamerZoneVisible] = useState(false);

  const [gamerZoneStyle, setGamerZoneStyle] = useState({
    display: "none",
    backgroundColor: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleGamerZone = () => {


    // setGamerZoneVisible(!gamerZoneVisible);

    // setSmartHomeVisible(false);
    // setHomeOfficeVisible(false);


    // setGamerZoneStyle({
    //   display: gamerZoneVisible ? "none" : "block",
    //   backgroundColor: gamerZoneVisible ? "" : "white",
    // });
    setGamerZoneStyle({
      display: "block",
      backgroundColor: "white",
    });

    // setSmartHomeStyle({
    //   display: "none",
    // });
    // setHomeOfficeStyle({
    //   display: "none",
    // });
  };

  const untoggleGamerZone = () => {
    setGamerZoneStyle({
      display: "none",
      backgroundColor: "",
    });
  }

  const untoggleSmartHome = () => {
    setSmartHomeStyle({
      display: "none",
      backgroundColor: "",
    });
  }

  const untoggleHomeOffice = () => {
    setHomeOfficeStyle({
      display: "none",
      backgroundColor: "",
    });
  }

  /**
* @constant
* @name homeOfficeVisible
* @type {boolean}
* 
* */
  const [homeOfficeVisible, setHomeOfficeVisible] = useState(false);
  const [homeOfficeStyle, setHomeOfficeStyle] = useState({
    display: "none",
    backgroundColor: "",
  });

  const toggleHomeOffice = () => {
    // setHomeOfficeVisible(!homeOfficeVisible);
    // setGamerZoneVisible(false);
    // setSmartHomeVisible(false);
    setHomeOfficeStyle({
      display: "block",
      backgroundColor: "white",
    });
    // setHomeOfficeStyle({
    //   display: homeOfficeVisible ? "none" : "block",
    //   backgroundColor: homeOfficeVisible ? "" : "white",
    // });

    // setGamerZoneStyle({
    //   display: "none",
    // });
    // setSmartHomeStyle({
    //   display: "none",
    // });
  };

  /**
* @constant
* @name smartHomeVisible
* @type {boolean}
* 
* */
  const [smartHomeVisible, setSmartHomeVisible] = useState(false);
  const [smartHomeStyle, setSmartHomeStyle] = useState({
    display: "none",
    backgroundColor: "",
  });

  const toggleSmartHome = () => {
    // setSmartHomeVisible(!smartHomeVisible);
    // setGamerZoneVisible(false);
    // setHomeOfficeVisible(false);

    // setSmartHomeStyle({
    //   display: smartHomeVisible ? "none" : "block",
    //   backgroundColor: smartHomeVisible ? "" : "white",
    // });
    setSmartHomeStyle({
      display: "block",
      backgroundColor: "white",
    });

    // setGamerZoneStyle({
    //   display: "none",
    // });
    // setHomeOfficeStyle({
    //   display: "none",
    // });
  };


  /**
* @function
* Petición HTTP que obtiene todas las categorias
* @returns {Array}
*/

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/categorias/get_all_categorias_navbar")
      .then((res) => {
        setCategoriasGamerZone(res.data.gamer_zone);
        setCategoriasHomeOffice(res.data.home_office);
        setCategoriasSmartHome(res.data.smart_home);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="container-fluid bg-skyblue d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
      <div className="container">
        <div className="row py-2">

          <div className="col-1 px-1 text-center"
            // onMouseOver={() => setGamerZoneHovered(true)}
            // onMouseOut={() => setGamerZoneHovered(false)}
            onMouseOver={() => toggleGamerZone()}
            onMouseOut={() => untoggleGamerZone()}
          // onClick={toggleGamerZone}
          >
            <a className="hooverturq">
              <img
                loading="eager"
                src={gamerZoneHovered ? gamerzoneturq : gamerzone}
                alt="Logo Gamer Zone"
                title="Logo Gamer Zone"
                width={44}
                height={36}
              />
              <p className="small mb-0">Gamer Zone</p>
              <i class="fa-solid fa-angle-down"></i>
            </a>


            <div className="bg-white p-1 div-listado-categorias"
              id="div_gamer_zone"
              style={gamerZoneStyle}
            >
              {categoriasGamerZone && categoriasGamerZone.map((categoria, index) => (

                <div
                  className="text-decoration-none text-dark"
                  onClick={() => {
                    location.href = `/gamer-zone/${categoria.nombre}`
                  }}
                  key={index}
                >
                  <div className="col-lg-12 col-md-12 col-12 text-start fs-14 p-2 li-categoria">
                    <>
                      {categoria.nombre_mostrar}
                    </>
                  </div>
                </div>

              ))}

            </div>


          </div>



          <div
            className="col-1 px-1 text-center"
            onMouseOver={() => toggleHomeOffice()}
            onMouseOut={() => untoggleHomeOffice()}
          // onMouseOver={() => setHomeOfficeHovered(true)}
          // onMouseOut={() => setHomeOfficeHovered(false)}
          // onClick={toggleHomeOffice}
          >
            <a className="hooverturq">
              <img
                loading="eager"
                src={homeOfficeHovered ? homeofficeturq : homeoffice}
                alt="Logo Home Office"
                title="Logo Home Office"
                width={44}
                height={36}
              />
              <p className="small mb-0">Home Office</p>
              <i class="fa-solid fa-angle-down"></i>
            </a>
            <div
              className="bg-white p-1 div-listado-categorias"
              id="div_home_office"
              style={homeOfficeStyle}
            >
              {categoriasGamerZone && categoriasHomeOffice?.map((categoria, index) => (
                <a
                  className="text-decoration-none text-dark"
                  onClick={() => {
                    location.href = `/home-office/${categoria.nombre}`
                  }}
                  key={index}
                >
                  <div className="col-lg-12 col-md-12 col-12 text-start fs-14 p-2 li-categoria">
                    {categoria.nombre_mostrar}
                  </div>
                </a>
              ))}
            </div>
          </div>


          <div
            className="col-1 px-1 text-center separadorcategorias"
            onMouseOver={() => toggleSmartHome()}
            onMouseOut={() => untoggleSmartHome()}
          // onClick={toggleSmartHome}
          // onMouseOver={() => setSmartHomeHovered(true)}
          // onMouseOut={() => setSmartHomeHovered(false)}
          // onClick={toggleSmartHome}
          >
            <a className="hooverturq">
              <img
                loading="eager"
                src={smartHomeHovered ? smarthometurq : smarthome}
                alt="Logo Smart Home"
                title="Logo Smart Home"
                width={44}
                height={36}
              />
              <p className="small mb-0">Smart Home</p>
              <i class="fa-solid fa-angle-down"></i>
            </a>
            <div
              className="bg-white p-1 div-listado-categorias"
              id="div_smart_home"
              style={smartHomeStyle}
            >
              {categoriasSmartHome && categoriasSmartHome?.map((categoria, index) => (
                <a
                  className="text-decoration-none text-dark"
                  onClick={() => {
                    location.href = `/smart-home/${categoria.nombre}`
                  }}
                  key={index}
                >
                  <div className="col-lg-12 col-md-12 col-12 text-start fs-14 p-2 li-categoria">
                    {categoria.nombre_mostrar}
                  </div>
                </a>
              ))}
            </div>
          </div>


          {/* <div className="col-1  text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/home-office/cyber";
                dispatch(setBooleanCategoria("7"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={cyber}
                alt="CyberTec"
                title="CyberTec"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">CyberTec</p>
            </a>
          </div> */}

          <div className="col-1  text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/gamer-zone/simuladores";
                dispatch(setBooleanCategoria("1"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat1}
                alt="Sillas Gamer"
                title="Sillas Gamer"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Simuladores</p>
            </a>
          </div>

          <div className="col-1  text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/home-office/monitores";
                dispatch(setBooleanCategoria("2"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat2}
                alt="Monitores"
                title="Monitores"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Monitores</p>
            </a>
          </div>
          <div className="col-1 text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/home-office/impresoras";
                dispatch(setBooleanCategoria("3"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat6}
                alt="Impresoras"
                title="Impresoras"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Impresoras</p>
            </a>
          </div>
          <div className="col-1 text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/smart-home/tablets";
                dispatch(setBooleanCategoria("4"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat51}
                alt="Tablets"
                title="Tablets"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Tablets</p>
            </a>
          </div>
          <div className="col-1  text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/search?q=notebooks";
                dispatch(setBooleanCategoria("5"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat3}
                alt="Notebooks"
                title="Notebooks"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Notebooks</p>
            </a>
          </div>
          <div className="col-1 text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/home-office/computadores";
                dispatch(setBooleanCategoria("6"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat4}
                alt="Computadores"
                title="Computadores"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Computadores</p>
            </a>
          </div>
          <div className="col-1  text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/home-office/escaner";
                dispatch(setBooleanCategoria("7"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat99}
                alt="Escaner"
                title="Escaner"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Escaners</p>
            </a>
          </div>

          <div className="col-1 text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/smart-home/smart-tv";
                dispatch(setBooleanCategoria("8"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat9}
                alt="Smart-TV"
                title="Smart-TV"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Smart TV</p>
            </a>
          </div>

          <div className="col-1 text-center hooverturq">
            <a
              onClick={() => {
                location.href = "/gamer-zone/accesorios";
                dispatch(setBooleanCategoria("9"));
                dispatch(setBoolean(false));

              }}
              className="nonehyper"
            >
              <img
                loading="eager"
                className="hoversubcategoria mt-0"
                src={subcat8}
                alt="Accesorios"
                title="Accesorios"
                width={44}
                height={36}
              />
              <p className="fs-12 mb-0">Accesorios</p>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default NavCategorias;
