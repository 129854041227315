/**
 * Componente IngresarDireccion:
 * 
 * acá se muestra el modal para ingresar la dirección del despacho 
 * 
 * @module ComponenteIngresarDireccionjsx
 */

import { useState, useEffect } from "react";
import "./ingresardireccion.css";
import axios from "axios";

import logoretiro from "../../assets/Modales/Group20.png";

import { useDispatch } from "react-redux";
import { setTipoEnvio } from "../../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../../redux/fechaEntregaSlice";
import { setComunaSelected } from "../../redux/comunaSelectedSlice";
import { setRegionSelected } from "../../redux/regionSelectedSlice";

const IngresarDirecion = () => {

  /**
* @constant
* @name envio
* @type {string}
* 
* */

  const [envio, setEnvio] = useState("");


  /**
* @constant
* @name regiones
* @type {Array}
*
* */
  const [regiones, setRegiones] = useState([]);

  /**
* @constant
* @name comunas
* @type {Array}
*
* */
  const [comunas, setComunas] = useState([]);

  /**
* @constant
* @name selectedRegion
* @type {string}
*
* */
  const [selectedRegion, setSelectedRegion] = useState("");


  /**
* @constant
* @name selectedComuna
* @type {string}
*
* */
  const [selectedComuna, setSelectedComuna] = useState("");

  /**
* @constant
* @name usuarioId
* @type {string}
*
* */
  const [usuarioId, setUsuarioId] = useState("");

  /**
* @constant
* @name userAlready
* @type {string}
*
* */
  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  const dispatch = useDispatch();

  /**
  * @function
  * Petición HTTP para registrar el Id random del usuario
  * @returns {string}
  * 
  */
  const generateRandomId = () => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/usuario/register_usuario_id")
      .then((response) => {
        sessionStorage.setItem("IDusuario", response.data.id);
        return response.data.id;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
* @function
* Petición HTTP para obtener todas las regiones
* 
*/
  useEffect(() => {
    if (userAlready == null)
      setUsuarioId(generateRandomId());
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/region/get_regiones")
      .then((response) => {
        setRegiones(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  /**
* @function
* Petición HTTP para obtener todas las comunas por la región seleccionada
* 
*/
  useEffect(() => {
    if (selectedRegion !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/comuna/get_comunas_by_region/${selectedRegion}`
        )
        .then((response) => {
          setComunas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setComunas([]);
    }
  }, [selectedRegion]);

  /**
* @function
* Metodo que setea el useState con la región seleccionada
*/
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedComuna("");
    setEnvio("");
  };


  /**
* @function
* Metodo que setea el useState con la comuna seleccionada
*/
  const handleComunaChange = (event) => {
    setSelectedComuna(event.target.value);
  };

  function handleChangeEnvio(event) {
    setEnvio(event.target.value);
  }

  function handleClickEnvio() {
    dispatch(setTipoEnvio(4));
    dispatch(setComunaSelected("Retiro en tienda"));
    setSelectedComuna("");
    setSelectedRegion("");
    setTimeout(() => {
      document.getElementById("btn_closeid").click();
    }, 1000);
  }

  /**
* @function
* Petición HTTP para registrar la dirección del usuario
* @param {string} usuarioId Id del usuario
* @param {string} selectedRegion  Id de la región seleccionada
* @param {string} selectedComuna  Id de la comuna seleccionada
* @returns {string}
*/
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_BACK_URL + "/api/direccion/register_direccion", {
        usuario: userAlready ? userAlready : usuarioId,
        region: selectedRegion,
        comuna: selectedComuna,
        location: "home",
      })
      .then((response) => {
        dispatch(setTipoEnvio(response.data.direccion.codigo_envio));
        dispatch(setFechaEntrega(response.data.direccion.fecha));
        dispatch(setComunaSelected(response.data.direccion.nombre_comuna));
        dispatch(setRegionSelected(response.data.direccion.nombre_region));

      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      document.getElementById("btn_closeid").click();
    }, 1000);
  };

  return (
    <>
      <div
        className="modal fade"
        id="ingresar-domicilio"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 rounded">
              <button
                type="button"
                id="btn_closeid"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pt-0">
              <div className="container">
                <div className="row">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-black text-center mb-4">
                    <p>
                      Mejora tu experiencia y conoce los{" "}
                      <b className="bold">valores de envio</b> y{" "}
                      <b className="bold">fechas estimadas</b>
                      <br /> de la llegada de{" "}
                      <b className="bold">tus productos</b>.
                    </p>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 text-start fw-normal fs-14 mb-4 bordernone border-end">
                    <label className="d-xs-none" htmlFor="">
                      Región<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select rounded-pill mb-2"
                      name="form-select"
                      id="region"
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      required
                      placeholder="Seleccione una opción"
                    >
                      <option value="">Selecciona una región</option>
                      {regiones.map((region) => (
                        <option key={region.id} value={region.id}>
                          {region.nombre_region}
                        </option>
                      ))}
                    </select>
                    <label className="d-xs-none" htmlFor="">
                      Comuna<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select rounded-pill "
                      name=""
                      id="comuna"
                      value={selectedComuna}
                      onChange={handleComunaChange}
                      disabled={comunas.length === 0}
                      required
                    >
                      <option value="">Selecciona una comuna</option>
                      {comunas.map((comuna) => (
                        <option key={comuna.id} value={comuna.id}>
                          {comuna.nombre_comuna}
                        </option>
                      ))}
                    </select>

                    <button
                      type="button"
                      aria-label="Aceptar"
                      id="btn_aceptar_domicilio"
                      title="Aceptar"
                      disabled={selectedComuna === ""}
                      className="mt-3 btn w-100 py-1 rounded-pill text-white fw-bold btn_aceptar_domicilio bg-azul-black btn-azul"
                      onClick={handleSubmit}
                    >
                      Guardar y continuar
                    </button>
                  </div>
                  <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
                    <hr />
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12  mb-2 bordernone border-end text-center">
                    <img
                      loading="lazy"
                      src={logoretiro}
                      className={`img-fluid mb-3 centermovile ${envio === "1" ? "shake" : ""
                        }`}
                      alt="Envío de producto"
                      title="Envío de producto"
                    />
                    <div>
                      <input
                        className="centermovile form-check-input"
                        value="1"
                        type="radio"
                        name="tipo_envio2"
                        id="retirar_en_tienda_check2"
                        checked={envio === "1"}
                        onChange={handleChangeEnvio}
                        onClick={handleClickEnvio}
                      />
                      <label
                        className="form-check-label mx-2"
                        htmlFor="retirar_en_tienda_check"
                      >
                        Retirar en tienda, solo en:
                      </label>
                      <p className="mb-0 fw-bold">
                        Av. Manuel Montt 1667, Local 2, Providencia, Santiago
                      </p>
                    </div>
                  </div>
                  <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
                    <hr />
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-12  mt-2">
                    <a
                      className="mt-5 btn w-100 rounded-pill text-white fw-bold btn_aceptar_domicilio bg-azul-black btn-azul"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Definir más tarde"
                    >
                      Definir más tarde
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IngresarDirecion;
